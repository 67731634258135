import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  getUserById(domain: string, userId: string) {
    return this.http.get('./public/domain/' + domain + '/user/' + userId);
  }

  getCalendar(domain: string, userId: string, date: Date) {
    return this.http.get('./public/domain/' + domain + '/user/' + userId + '/calendar',
      {params: {date : date.toLocaleDateString('sv'), cache: 'reset'}});
  }

}

export interface CalendarEvent {
  userId;
  candidateId;
  jobId;
  startTime?: string;
  startDate: Date;
  endTime?: string;
  endDate: Date;
  timeZone: string;
}
