import {Component, Inject, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { BaseComponent } from '../../components/base.component';
import { CustomizationService } from '../../services/customization/customization.service';

@Component({
  selector: 'app-header-limited',
  templateUrl: './header-limited.component.html',
  styleUrls: ['./header-limited.component.scss']
})
export class HeaderLimitedComponent extends BaseComponent implements OnInit {

  constructor(public translate: TranslateService,
              public router: Router,
              public route: ActivatedRoute,
              private customizationService: CustomizationService) {
    super();
    this.initializeProperties(window, route, this.translate);
  }
  navBarOpen = false;
  dropDown = false;
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : undefined;

  switchLanguage(language: string) {
    if (language) {
      this.translate.setDefaultLang(language);
      this.dropDown = false;
    }
  }

  toggleNavbar() {
    this.navBarOpen = !this.navBarOpen;
  }
  dropDownLang(element) {
    this.dropDown = !this.dropDown;
  }

  ngOnInit() {
    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
      }
    });
    BaseComponent.customization.subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.lang = this.translate.getDefaultLang();
        if (!this.lang && this.customization.defaultLanguage) {
          this.translate.setDefaultLang(this.customization.defaultLanguage);
        }
      }
    });
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        if (val.state.root.firstChild && val.state.root.firstChild.params.lang) {
          //this.translate.setDefaultLang(val.state.root.firstChild.params.lang);
        }
      }
    });
    this.translate.onDefaultLangChange.subscribe(v => {
      this.lang = this.translate.getDefaultLang();
    });
  }

}
