import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobInterviewService {

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  getJobInterview(domain: string, candidateId: string, interviewerId: string, passCode: string): Observable<JobInterview> {
    return this.http.get<JobInterview>('./public/domain/' + domain + '/candidate/' + candidateId + '/interview/' + interviewerId, {params: {passCode: passCode}});
  }

}

export interface JobInterview {
  startDate?: Date;
  endDate?: Date;
  interviewLength?: number;
  method?: string;
  standardAddress?: Address;
  phoneNumber?: string;
  visioConferenceURL?: string;
  interviewers: User[];
  jobId?: string;
  status: string;
}

export interface Address {
  address?: string;
  number?: string;
  street?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
}

export interface User {
  id?: string;
  name?: string;
  email?: string;
  photo?: Photo;
}


export interface Photo {
  url?: string;
}
