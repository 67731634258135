import {Component, HostListener} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private domain: string;

  constructor(private cookieService: CookieService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title) {
    this.domain = new URLSearchParams(window.location.search).get("domain") || 'aubainerie';
    if (window.location.hostname.split('.').length > 0) {
      if (window.location.hostname !== 'localhost' && window.location.hostname !== 'private.jobs.nextal.com') {
        this.domain = window.location.hostname.split('.')[0];
      }
    }
    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects,
        domain: this.domain
      });
    });
    /** END : Code to Track Page View  using gtag.js */

    // Add dynamic title for selected pages - Start
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
        titleService.setTitle(title);
      }
    });
    // Add dynamic title for selected pages - End
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  @HostListener('window:beforeunload')
  doSomething() {
    this.cookieService.delete('sessionId');
    this.cookieService.delete('referer');
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
