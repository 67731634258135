import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private http: HttpClient) { }

  get(domain: string, surveyId: string): Observable<any> {
    return this.http.get('./public/domain/' + domain + '/survey/' + surveyId );
  }

  submit(domain: string, surveyId: string, answers: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/survey/' + surveyId + "/answer", answers);
  }

}
