import { Deserializable } from "./deserializable.model";

export class Technology implements Deserializable {

  public id;
  public text;
  public experience;
  public $$error = {text: undefined};

  deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id;
      }
      if (input.text) {
        this.text = input.text;
      }
      if (input.experience) {
        this.experience = input.experience;
      }
    }
    return this;
  }

  hasError() {
    this.validate();
    return this.$$error.text !== undefined;
  };

  validate() {
    this.$$error = {text: undefined};
    if (this.text === undefined
      || this.text === "") {
      this.$$error.text = "Please provide a text";
    }
  };
}
