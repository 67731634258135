import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderComponent} from './core/header/header.component';
import {AppComponent} from './app.component';
import {Injector, NgModule} from '@angular/core';
import {FooterComponent} from './core/footer/footer.component';
import {HomeComponent} from './core/home/home.component';
import {JobDetailComponent} from './core/job-detail/job-detail.component';
import {SearchComponent} from './components/search/search.component';
import {SubmitComponent} from './components/submit/submit.component';
import {JobListComponent} from './components/job-list/job-list.component';
import {JobAlertComponent} from './components/job-alert/job-alert.component';
import {CandidateAccountComponent} from './components/candidate-account/candidate-account.component';
import {JobInterviewComponent} from './components/job-interview/job-interview.component';
import {JobDetailsComponent} from './components/job-details/job-details.component';
import {PageNotFoundComponent} from './core/page-not-found/page-not-found.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {MatButtonModule, MatCheckboxModule, MatMenuModule} from '@angular/material';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {APIInterceptor} from './services/api-interceptor/api-interceptor.service';
import {LanguageHostComponent} from './components/language & host/language-host.component';
import {BaseComponent} from './components/base.component';
import {FilterComponent} from './components/filter/filter.component';
import {ApplicationFormComponent} from './components/applicationForm/application-form.component';
import {FileUploadModule} from 'ng2-file-upload';
import {FormsModule} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {OrderModule} from 'ngx-order-pipe';
import {ContractLevelPipe} from './pipe/contract-pipe/contract-level.pipe';
import {LanguagePipe} from './pipe/language/language.pipe';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {CacheInterceptor} from './services/cache-interceptor/cache-interceptor.service';
import {CandidateComponent} from './components/candidate/candidate.component';
import {BaseLimitedComponent} from './components/base-limited.component';
import {HeaderLimitedComponent} from './core/header-limited/header-limited.component';
import {FooterLimitedComponent} from './core/footer-limited/footer-limited.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer2';
import {FeedbackComponent} from './components/feedback/feedback.component';
import { SurveyComponent } from './components/survey/survey.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function hostFactory() { return window.location.host; }
export let InjectorInstance: Injector;


@NgModule({
  declarations: [
    ContractLevelPipe,
    LanguagePipe,
    AppComponent,
    LanguageHostComponent,
    HeaderComponent,
    HeaderLimitedComponent,
    FooterComponent,
    FooterLimitedComponent,
    HomeComponent,
    JobDetailComponent,
    SubmitComponent,
    SearchComponent,
    FilterComponent,
    ApplicationFormComponent,
    JobListComponent,
    JobAlertComponent,
    CandidateComponent,
    CandidateAccountComponent,
    SurveyComponent,
    JobInterviewComponent,
    FeedbackComponent,
    JobDetailsComponent,
    PageNotFoundComponent,
    BaseComponent,
    BaseLimitedComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    HttpClientModule,
    MatMenuModule,
    OrderModule,
    NgxDocViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyByNdigyHuiyKKows37VIsX3BUGygGQ2E8'
    }),
    FileUploadModule,
    FormsModule,
  ],
  providers: [
    DatePipe,
    CookieService,
    { provide: 'BASE_API_URL',
      useValue: environment.apiUrl
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    { provide: 'HOSTNAME', useFactory: hostFactory }
    ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
