import { Component, Inject, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JobTemplateService } from '../../services/job-template/job-template.service';
import { JobService } from '../../services/job/job.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import { PipeFilterService } from '../../services/utils/pipeFilter.service';
import { ClientService } from '../../services/client/client.service';
import { JobApplicationService } from '../../services/job-application/job-application.service';
import { AngularFileUploaderService } from 'angular-file-uploader';
import { FileUploader } from 'ng2-file-upload';
import * as $ from 'jquery';
import { Candidate } from '../../models/candidate.model';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
})
export class ApplicationFormComponent extends BaseComponent implements OnInit {

  constructor(private http: HttpClient,
              private jobTemplateService: JobTemplateService,
              private customizationService: CustomizationService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private router: Router,
              private candidateService: CandidateService,
              private clientService: ClientService,
              private jobService: JobService,
              private cookies: CookieService,
              private jobApplicationService: JobApplicationService,
              private upload: AngularFileUploaderService,
              @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
}

  @Input() job: any;
  @Input() address: any;
  @Input() applying: any;
  @Input() fullpage: any;

  private applied;
  private addOtherFile;
  private appliedSuccess;
  private otherFileSuccess;
  multipleSelectSettings = {};
  multipleSelectProvinceSettings = {};
  languageLevel;
  languages;
  communicationChannels;
  validCountry;
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  private candidate;
  private type;
  private progressVisible;
  private percentComplete;
  private provinces;
  private cities;
  private error;
  private f;
  private errFile;
  private referer;

  preferredLanguage;
  preferredCommunicationChannel;
  frenchLevel;
  englishLevel;
  province;
  city;

  private provinceCityDictionary = [];

  public cvUploader: FileUploader = new FileUploader({
    url: this.baseUrl + '/files/parse/candidate?createCandidate=false',
    removeAfterUpload: false,
    autoUpload: false});

  public fileUploader: FileUploader = new FileUploader({
    url: this.baseUrl + '/public/files/',
    removeAfterUpload: false,
    autoUpload: false});

  ngOnInit() {
    this.cvUploader.queue.forEach(fileItem => {
        this.cvUploader.uploadItem(fileItem);
    });

    this.fileUploader.queue.forEach(fileItem => {
        this.cvUploader.uploadItem(fileItem);
    });


    this.applied = false;
    this.addOtherFile = false;
    this.appliedSuccess = false;
    this.otherFileSuccess = false;
    this.validCountry = true;

    if (window && window.sessionStorage && window.sessionStorage.candidate) {
      this.candidate = new Candidate().deserialize(JSON.parse(window.sessionStorage.candidate));
    } else {
      this.referer = this.route.snapshot.queryParamMap.get('ref');
      this.candidate = new Candidate();
      this.candidate.referenceFrom = this.referer || this.jobService.getSource(this.cookies.get('referer'));
    }
    this.type = 'CV';
    this.progressVisible = false;
    this.percentComplete = 0;
    this.provinces = [];
    this.cities = [];
    if (this.customization.hiddenCareerPortalProperties) {
      this.validateCountry();
    } else {
      this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
        if (response) {
          this.customization = response;
          this.validateCountry();

          if (this.profile.applyButtons) {
            this.profile.applyButtons.forEach(item => {
                if (item.language === this.translate.getDefaultLang()) {
                  this.profile.applyButton = item.label;
                }
              }
            );
          }
        }
      });
    }
    this.translate.onDefaultLangChange.subscribe(() => {
      this.lang = this.translate.getDefaultLang();
      if (this.profile.applyButtons) {
        this.profile.applyButtons.forEach(item => {
            if (item.language === this.translate.getDefaultLang()) {
              this.profile.applyButton = item.label;
            }
          }
        );
      }
    });

    this.clientService.getClientByDomain(this.domain).subscribe(response => {
      if (response && response._embedded) {
        const clients = response._embedded.clients;
        clients.forEach(client => {
          if (client.standardAddress) {
            this.provinces.push(client.standardAddress.province);
            this.cities.push(client.standardAddress.city);
            if (!this.provinceCityDictionary[client.standardAddress.province]) {
              this.provinceCityDictionary[client.standardAddress.province] = [];
            }
            this.provinceCityDictionary[client.standardAddress.province].push(client.standardAddress.city);
          }
        });
        this.provinces = PipeFilterService.filterUnique(this.provinces).filter(v => v !== '' && v != null);
        this.provinces = PipeFilterService.filterOrderByAlphabet(this.provinces);
        this.provinces = PipeFilterService.replaceProvinceName(this.provinces);

        this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
        this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);
      }
    });

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
        if (this.profile.applyButtons) {
          this.profile.applyButtons.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.applyButton = item.label;
              }
            }
          );
        }
      }
    });
    this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
      'UNSELECT_ALL']).subscribe(translations => {
      this.multipleSelectSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'id',
        textField: 'value',
        itemsShowLimit: 3,
        searchPlaceholderText: translations.SEARCH,
        selectAllText: translations.SELECT_ALL,
        unSelectAllText: translations.UNSELECT_ALL,
        allowSearchFilter: false
      };
      this.multipleSelectProvinceSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'sp',
        textField: 'p',
        itemsShowLimit: 3,
        searchPlaceholderText: translations.SEARCH,
        selectAllText: translations.SELECT_ALL,
        unSelectAllText: translations.UNSELECT_ALL,
        allowSearchFilter: false
      };
    });

    this.translate.get(['LANGUAGE_LEVEL_0', 'LANGUAGE_LEVEL_1',
                              'LANGUAGE_LEVEL_2', 'LANGUAGE_LEVEL_3',
                              'LANGUAGE_LEVEL_4', 'LANGUAGE_LEVEL_5']).subscribe(translations => {
      this.languageLevel = [
        { id  : 0, value : translations.LANGUAGE_LEVEL_0},
        { id  : 1, value : translations.LANGUAGE_LEVEL_1},
        { id  : 2, value : translations.LANGUAGE_LEVEL_2},
        { id  : 3, value : translations.LANGUAGE_LEVEL_3},
        { id  : 4, value : translations.LANGUAGE_LEVEL_4},
        { id  : 5, value : translations.LANGUAGE_LEVEL_5},
      ];
    });

    this.translate.get(['FRENCH', 'ENGLISH']).subscribe(translations => {
      this.languages = [
        { id  : 'fr', value : translations.FRENCH},
        { id  : 'en', value : translations.ENGLISH}
      ];
    });

    this.translate.get(['EMAIL', 'PHONE_CALL',  'PHONE_TEXT']).subscribe(translations => {
      this.communicationChannels = [
        { id  : 'EMAIL', value : translations.EMAIL},
        { id  : 'PHONE_CALL', value : translations.PHONE_CALL},
        { id  : 'PHONE_TEXT', value : translations.PHONE_TEXT}
      ];
    });

  }

  validateCountry() {
    if (this.customization.hiddenCareerPortalProperties.countriesCanApply) {
      this.http.get('https://ipinfo.io?token=92f557f01adab8').subscribe((response: any) => {
        if (response) {
          const country = response.country;
          // var ipInfo = response;

          if (!this.customization.hiddenCareerPortalProperties.countriesCanApply) {
            this.validCountry = true;
          } else if(this.customization.hiddenCareerPortalProperties.countriesCanApply.length == 0) {
            this.validCountry = true;
          } else if(this.customization.hiddenCareerPortalProperties.countriesCanApply.indexOf(country) > -1) {
            this.validCountry = true;
          } else {
            this.validCountry = false;
          }
        }
      });
    }
  }

  hasError() {
    const properties = ['name', 'email', 'phoneNumber', 'description', 'actualPosition',
      'actualSalary', 'requestedSalary', 'preferredLanguage', 'preferredCommunicationChannel',
      'frenchLevel', 'englishLevel', 'address', 'website', 'facebook', 'linkedin', 'monster',
      'twitter', 'instagram', 'viadeo', 'github', 'availableDate'];
    this.candidate.$$error = {};
    let foundError = false;

    if (this.preferredLanguage && this.preferredLanguage[0]) {
      this.candidate.preferredLanguage = this.preferredLanguage[0].id;
    }
    if (this.preferredCommunicationChannel && this.preferredCommunicationChannel[0]) {
      this.candidate.preferredCommunicationChannel = this.preferredCommunicationChannel[0].id;
    }
    if (this.frenchLevel && this.frenchLevel[0]) {
      this.candidate.frenchLevel = this.frenchLevel[0].id;
    }
    if (this.englishLevel && this.englishLevel[0]) {
      this.candidate.englishLevel = this.englishLevel[0].id;
    }
    this.candidate.province = this.province !== undefined ? this.province[0].sp : undefined;
    this.candidate.city = this.city !== undefined ? this.city[0] : undefined;
    for  (let i = 0; i < properties.length; i++) {
      let property = properties[i];
      if (this.customization.careerPortalApplicantField[property] &&
          this.customization.careerPortalApplicantField[property].required) {
        if (property === 'phoneNumber') {
          this.cleanPhoneNumber(this.candidate[property]);
        }
        if (this.candidate[property] === undefined  || this.candidate[property] === '') {
          this.candidate.$$error[property] = this.translate.get('ENTER_VALUE');
          foundError = true;
        }
        if (property === 'name' && !this.validateName(this.candidate[property])) {
          this.candidate.$$error[property] = this.translate.get('ENTER_FULL_NAME');
          foundError = true;
        }

        if (property === 'email' && !this.validateEmail(this.candidate[property])) {
          this.candidate.$$error[property] = this.translate.get('ENTER_VALID_EMAIL');
          foundError = true;
        }
      }
    }

    if (this.address === undefined && this.provinces.length > 0 && this.cities.length >= 0) {
      if (this.candidate.province === undefined || this.candidate.province.trim() === '') {
        this.candidate.$$error.province = this.translate.get('ENTER_VALUE');
        foundError = true;
      }
      if (this.candidate.city === undefined || this.candidate.city.trim() === '') {
        this.candidate.$$error.city = this.translate.get('ENTER_VALUE');
        foundError = true;
      }
    }
    return foundError;
  }

  cleanPhoneNumber(phoneNumber) {
    if (phoneNumber !== undefined) {
      phoneNumber = phoneNumber.replace(/[^0-9\.]+/g, '');
      return phoneNumber;
    } else {
      return undefined;
    }

  }

  validateName(name) {
    if (name !== undefined) {
      return name.indexOf(' ') > -1;
    } else {
      return false;
    }

  }

  validateEmail(email) {
    let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  createAccount() {
    if (!this.hasError()) {
      $('#application').slideUp();
      $('#loadingFile').slideDown();
      if (!this.candidate.id) {

        if (this.candidate.availableDate) {
          this.candidate.$$availableDate = this.candidate.availableDate;
          if (!this.candidate.availableDate.endsWith('T00:00:00.000Z')) {
            this.candidate.availableDate += 'T00:00:00.000Z';
          }
        }
        this.candidate.preferredLanguage = this.lang !== undefined ? this.lang : 'en';
        this.candidateService.save(this.domain, this.candidate).subscribe(resource => {
          this.candidate = new Candidate().deserialize(resource);
          window.sessionStorage.setItem('candidate', JSON.stringify(this.candidate));
          this.apply(this.candidate, this.job);
        }, response => {
          // There was an http error returned
          if (response.status == '409') {
            // TODO: Add the files to the existing candidate
            this.candidate.id = response.error.id;
            this.candidateService.update(this.domain, this.candidate).subscribe(resource => {
              window.sessionStorage.setItem('candidate', JSON.stringify(this.candidate));
              this.candidate.availableDate = this.candidate.$$availableDate;
              this.apply(this.candidate, this.job);
            });
          } else {
            this.error = this.translate.get('CANT_APPLY');
            this.candidate.availableDate = this.candidate.$$availableDate;
            $('#loadingFile').slideUp();
            $('#application').slideDown();
          }
        });
      } else {
        this.apply(this.candidate, this.job);
      }
    }
  }

  interested() {
    if (this.candidate === undefined || this.candidate.id === undefined) {
      this.applying = true;
    } else {
      this.applying = true;
      if (this.customization.askCV) {
        $('#resume').slideUp();
        $('#application').show();
        $('#application').slideDown();
      } else {
        $('#application').show();
        $('#application').slideUp();
      }
    }
    setTimeout(() => {                           // <<<---using ()=> syntax
      const button = document.getElementById('datetimepickerbutton') as HTMLInputElement | null;
      if (button) {
        button.addEventListener('click', async () => {
          try {
            
            // @ts-ignore
            const input = document.getElementById('datetimepicker') as HTMLInputElement | null;
            input.type = 'date';
            // @ts-ignore
            await input.showPicker();
            // A date picker is shown.
          } catch (error) {
            // Use external library when this fails.
          }
        });
      }
    }, 2000);
  }

  apply(candidate, job) {
    if (this.applied) {
      return;
    }
    this.applied = true;
    const jobApplication = {
      candidateId: candidate.id,
      jobId: this.job ? this.job.id : null,
      source: this.referer || this.jobService.getSource(this.cookies.get('referer')),
      province : this.address ? this.address.province : this.candidate.province,
      city : this.address ? this.address.city : this.candidate.city
    };

    this.jobApplicationService.save(this.domain, jobApplication).subscribe(resource => {
      this.appliedSuccess = true;
      this.addOtherFile = true;
      $('#loadingFile').slideUp();
      // this.jobApplicationService.saveNotification(this.domain, this.candidate.id, this.job ? this.job.id : null,
      //   jobApplication);
    }, response => {
      if (response.status == '409') {
        // Already applied for this job
        $('#loadingFile').slideUp();
        $('#application').slideDown();
        this.translate.get(['DUPLICATED_APPLICATION']).subscribe(translations => {
          this.error = translations.DUPLICATED_APPLICATION;
        });
      } else {
        $('#loadingFile').slideUp();
        $('#application').slideDown();
        this.translate.get(['APPLICATION_FAILED']).subscribe(translations => {
          this.error = translations.APPLICATION_FAILED;
          this.applied = false;
        });
      }
    });
  }

  addCV($event) {
    const file = $event.target.files[0];
    this.f = file;
    this.errFile = null;
    this.error = null;
    if (file) {
      this.progressVisible = true;
      $('#resume').slideUp();
      $('#loadingFile').slideDown();
      this.cvUploader.queue.forEach(fileItem => {
        if (fileItem.file.name === file.name) {
          fileItem.file.name = PipeFilterService.removeAccents(file.name);
          this.cvUploader.options.headers = [
            {name : 'X-ReferenceFrom', value: 'NEXTAL_JOB_BOARD'},
            {name: 'Filename', value: fileItem.file.name},
            {name: 'Domain', value: this.domain}
          ];
          this.cvUploader.uploadItem(fileItem);
        }
      });
      this.cvUploader.onCompleteItem = (item: any, localCandidate: any, status: any, headers: any) => {

        // There was an http error returned
        if (status === 200) {
          $('#loadingFile').slideUp();
          $('#application').slideDown();
          // this.candidate = new Candidate().deserialize(JSON.parse(response));
          // window.sessionStorage.setItem('candidate', JSON.stringify(this.candidate));
          localCandidate = JSON.parse(localCandidate);
          const file = {
            name: item.file.name,
            id: localCandidate.files[0].id,
            type: this.type,
            text: localCandidate.files[0].text
          };
          this.candidate.new = true;
          this.candidate.phoneNumber = localCandidate.phoneNumber;
          this.candidate.address = localCandidate.address;
          this.candidate.email = localCandidate.email;
          this.candidate.linkedin = localCandidate.linkedin;
          this.candidate.name = localCandidate.name;
          this.candidate.phoneNumber = localCandidate.phoneNumber;
          this.candidate.files.push(file);
        } else {
          this.cvUploader.clearQueue();

          this.translate.get(['ERROR_FILE']).subscribe(translations => {
            this.error = translations.ERROR_FILE;
          });

          $('#resume').slideDown();
          $('#loadingFile').slideUp();
        }
      };
    }
  }

  addFile($event) {
    const file = $event.target.files[0];
    this.f = file;
    this.errFile = null;
    this.error = null;
    if (file) {
      this.progressVisible = true;
      $('#otherFile').slideUp();
      $('#loadingFile').slideDown();
      this.fileUploader.queue.forEach(fileItem => {
        if (fileItem.file.name === file.name) {
          fileItem.file.name = PipeFilterService.removeAccents(file.name);
          this.fileUploader.options.headers = [
            {name : 'X-ReferenceFrom', value: 'NEXTAL_JOB_BOARD'},
            {name: 'Filename', value: fileItem.file.name},
            {name: 'Domain', value: this.domain}
          ];
          this.fileUploader.uploadItem(fileItem);
        }
      });
      this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

        // There was an http error returned
        if (status === 200) {
          const document = JSON.parse(response);
          document.name = document.filename;
          this.candidateService.addFile(this.domain, this.candidate, document).subscribe(resource => {
            $('#loadingFile').slideUp();
            this.otherFileSuccess = true;
          });
        }
      };
    }
  }

  filterCities(provinceSelected) {
    this.city = undefined;
    this.cities = PipeFilterService.filterCities(provinceSelected.sp, this.provinceCityDictionary);
    this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
    this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);
  }
}
