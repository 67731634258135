import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base.component';
import { CustomizationService } from '../../services/customization/customization.service';

@Component({
  selector: 'app-language',
  template: '<router-outlet></router-outlet>'
})
export class LanguageHostComponent extends BaseComponent {

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private customizationService: CustomizationService,
              private translate: TranslateService,
              @Inject('HOSTNAME') private host: string,
              private route: ActivatedRoute) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  ngOnInit(): void {
    BaseComponent.initializeCustomization(this.customizationService, this.route, this.domain);
    BaseComponent.setDynamicStyle(this.domain);
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        if (val.state.root) {
          this.lang = val.state.root.firstChild.params['lang'];
        } else {
          this.lang = 'en';
        }
      }
    });
    if(!this.lang) {
      this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
        if (response) {
          if (response.defaultLanguage) {
            this.router.navigate(['/' + response.defaultLanguage]).then(() =>  {
              window.location.reload();
            });
          } else {
            this.router.navigate(['/fr']).then(() =>  {
              window.location.reload();
            });
          }
        }
      });
    }
    this.translate.onDefaultLangChange.subscribe(v => {
      this.updateUrlOnLanguageChange();
    });
  }

  updateUrlOnLanguageChange() {
    const url = this.router.url;
    const language = this.translate.getDefaultLang() ? this.translate.getDefaultLang() : 'en';
    const modifiedPath =  url.substring(0, url.indexOf('/') + 1) + language + url.substring(url.indexOf('/') + 3);
    this.router.navigateByUrl(modifiedPath);
  }
}
