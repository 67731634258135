import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
              private translate: TranslateService,
              private customizationService: CustomizationService,
              private route: ActivatedRoute) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  ngOnInit() {
  }

}
