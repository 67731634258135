import {Component, Inject, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {TranslateService} from '@ngx-translate/core';
import {CandidateAccount} from '../../models/candidate-account.model';
import {PipeFilterService} from '../../services/utils/pipeFilter.service';
import {ClientService} from '../../services/client/client.service';
import {ActivatedRoute} from '@angular/router';
import {JobTemplateService} from '../../services/job-template/job-template.service';
import {CandidateAccountService} from '../../services/candidateAccount/candidateAccount.service';
import {JobAlertSubscription} from '../../models/job-alert-subscription.model';

@Component({
  selector: 'app-candidate-account',
  templateUrl: './candidate-account.component.html',
  styleUrls: ['./candidate-account.component.scss']
})
export class CandidateAccountComponent extends BaseComponent implements OnInit {

  constructor(
    private candidateAccountService: CandidateAccountService,
    private jobTemplateService: JobTemplateService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }
  citiesList = [];
  selectedCities = [];
  citiesSettings = {};
  selectedJobTemplate = [];
  jobTemplateSettings = {};
  private candidate;
  private provinces;
  private cities;
  loading = true;
  private error = false;
  private validated = false;
  private newSubscription = new JobAlertSubscription();
  applying;
  style = BaseComponent.styleJobAlert ? BaseComponent.styleJobAlert : false;
  styleColorText = BaseComponent.styleColorJobAlert ? BaseComponent.styleColorJobAlert : false;
  private provinceCityDictionary = [];
  private jobTemplates: any[];

  ngOnInit() {
    this.applying = false;
    this.newSubscription.cities = new Array();
    this.newSubscription.jobTemplateId = '';
    this.provinces = [];
    this.cities = [];
    this.clientService.getClientByDomain(this.domain).subscribe(response => {
      if (response && response._embedded) {
        const clients = response._embedded.clients;
        clients.forEach(client => {
          if (client.standardAddress) {
            this.cities.push(client.standardAddress.city);
          }
        });

        this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
        this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);

        this.cities.forEach(element => {
          this.citiesList.push({ item_id: element, item_text: element });
        });
      }
    });
    this.jobTemplateService.getJobTemplate(this.domain).subscribe((response: any) => {

      // set job templates
      this.jobTemplates = response;
      this.setInformationList(this.jobTemplates, 'localizedJobs', 'title', this.translate);
      if (this.getUrlParam(this.route, 'action') === 'validate') {
        this.validateCandidate();
      } else {
        this.doLoadCandidate();
      }
    });

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        if (response.backgroundJobAlertId) {
          this.styleColorText = {
            color : response.jobAlertTextColor
          };
          this.style = {
            color : response.jobAlertTextColor,
            background: 'url(' + this.baseUrl + '/files/' + response.backgroundJobAlertId + ')',
            'background-repeat': 'no-repeat',
            'background-position-x': 'center'
          };
        }
      }
    });
    this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
      'UNSELECT_ALL']).subscribe(translations => {
      this.citiesSettings =  {
        enableCheckAll: false,
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.jobTemplateSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'id',
        textField: 'title',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });
  }

  hasError() {
    this.candidate.validate();
    if (this.candidate.jobTemplate === '') {
      this.candidate.$$error.jobTemplate = 'Please enter a valid job type';
    }
    if (this.candidate.province === '') {
      this.candidate.$$error.province = 'Please enter a valid job type';
    }
    if (this.candidate.city === '') {
      this.candidate.$$error.city = 'Please enter a valid job type';
    }
    return this.candidate.$$error.jobTemplate !== undefined
      || this.candidate.$$error.province !== undefined
      || this.candidate.$$error.city !== undefined
      || this.candidate.hasError();
  }

  updateCandidateAccount() {
    if(!this.candidate.hasError()) {
      this.candidateAccountService.update(this.domain, this.candidate, this.getQueryParam(this.route, 'passCode'))
        .subscribe(resource => {
      });
    }
  }

  private doLoadCandidate() {
    this.candidate = new CandidateAccount();
    this.candidateAccountService.get(this.domain, this.getUrlParam(this.route, 'candidateAccountId'),
      this.getQueryParam(this.route, 'passCode')).subscribe(resource => {
      this.candidate = new CandidateAccount().deserialize(resource);
      this.enrichCandidateAccount();
      this.candidate.jobTemplate = '';
      this.candidate.province = '';
      this.candidate.city = '';
      this.loading = false;
    }, response => {
      // There was an http error returned
      this.error = true;
      this.loading = false;
    });
  }

  private validateCandidate() {
    this.candidateAccountService.validate(this.domain, this.getUrlParam(this.route, 'candidateAccountId'),
      this.getQueryParam(this.route, 'passCode')).subscribe(resource => {
      this.doLoadCandidate();
      this.validated = true;
    }, response => {
      // There was an http error returned
      this.error = true;
      this.loading = false;
    });
  }
  enrichCandidateAccount() {
    if (this.candidate.jobAlertSubscriptions) {
      for (let i = 0; i < this.candidate.jobAlertSubscriptions.length; i++) {
        let jobSubscription = this.candidate.jobAlertSubscriptions[i];
        if (!jobSubscription.$$jobTemplate) {
          jobSubscription.$$jobTemplate = this.jobTemplates.find(jobTemplate =>
             jobTemplate.id == jobSubscription.jobTemplateId
          );
        }
      };
    }
  }

  resendLink() {
    this.applying = true;
    this.candidateAccountService.resendEmail(this.domain, this.candidate).subscribe(resource => {
      // this.candidate = new CandidateAccount().deserialize(resource);
      this.applying = false;
    }, response => {
      this.error = true;
      this.applying = false;
    });
  }

  delete(jobSubscription) {
    this.applying = true;
    this.candidate.jobAlertSubscriptions.splice(this.candidate.jobAlertSubscriptions.indexOf(jobSubscription), 1);
    this.candidateAccountService.update(this.domain, this.candidate, this.getQueryParam(this.route, 'passCode')).subscribe(resource => {
      this.candidate = new CandidateAccount().deserialize(resource);
      this.enrichCandidateAccount();
      this.applying = false;
    }, response => {
      this.error = true;
      this.applying = false;
    });
  }

  addSubscription() {
    this.newSubscription = new JobAlertSubscription();
    if (this.selectedJobTemplate && this.selectedJobTemplate.length > 0) {
      this.newSubscription.jobTemplateId = this.selectedJobTemplate[0].id;
    }
    if (this.selectedCities && this.selectedCities.length > 0) {
      this.selectedCities.forEach(city => {
        this.newSubscription.cities.push(city.item_id);
      });
    }
    if (!this.newSubscription.hasError()) {
      this.applying = true;
      if (!this.candidate.jobAlertSubscriptions) {
        this.candidate.jobAlertSubscriptions = new Array();
      }
      this.candidate.jobAlertSubscriptions.push(this.newSubscription);
      this.candidateAccountService.update(this.domain, this.candidate, this.getQueryParam(this.route, 'passCode')).subscribe(resource => {
        this.candidate = new CandidateAccount().deserialize(resource);
        this.newSubscription = new JobAlertSubscription();
        this.newSubscription.cities = new Array();
        this.newSubscription.jobTemplateId = '';
        this.selectedJobTemplate = [];
        this.selectedCities = [];
        this.enrichCandidateAccount();
        this.applying = false;
      }, response => {
        this.candidate.jobAlertSubscriptions.splice(this.candidate.jobAlertSubscriptions.indexOf(this.newSubscription), 1);
        // this.error = true;
        this.applying = false;
      });
    }
  }
}
