import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client/client.service';
import { Profile } from '../../models/profile.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  constructor(private customizationService: CustomizationService,
              private route: ActivatedRoute,
              public translate: TranslateService,
              private clientService: ClientService,
              private router: Router) {
    super();
    this.lang = this.translate.getDefaultLang();
    this.initializeProperties(window, route, this.translate);
  }

  private careerPortalFooter;
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : undefined;
  protected lang: string;

  ngOnInit() {
    BaseComponent.customization.subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.careerPortalFooter = response.careerPortalFooter;
      }
    });
    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;

        if (response && response.jobAlertTexts) {
          for (let i = 0; i < response.jobAlertTexts.length; i++) {
            if (response.jobAlertTexts[i].language === this.translate.getDefaultLang()) {
              if(response.jobAlertTexts[i].label &&  response.jobAlertTexts[i].label.trim() != '') {
                this.profile.jobAlertText = response.jobAlertTexts[i].label;
              }
            }
          }
        }
        if (response && response.descriptions) {
          for (let i = 0; i < response.descriptions.length; i++) {
            if (response.descriptions[i].language === this.translate.getDefaultLang()) {
              if(response.descriptions[i].label &&  response.descriptions[i].label.trim() != '') {
                this.profile.description = response.descriptions[i].label;
              }
            }
          }
        }
        if (response && response.links) {
          for (let i = 0; i < response.links.length; i++) {
            for (let j = 0; j < response.links[i].labels.length; j++) {
              if (response.links[i].labels[j].language === this.translate.getDefaultLang()) {
                this.profile.links[i].label = response.links[i].labels[j].label;
              }
            }
          }
        }
        if (response && response.addresses) {
          for (let i = 0; i < response.addresses.length; i++) {
            for (let j = 0; j < response.addresses[i].labels.length; j++) {
              if (response.addresses[i].labels[j].language === this.translate.getDefaultLang()) {
                this.profile.addresses[i].label = response.addresses[i].labels[j].label;
              }
            }
          }
        }
        if (response && response.emails) {
          for (let i = 0; i < response.emails.length; i++) {
            for (let j = 0; j < response.emails[i].labels.length; j++) {
              if (response.emails[i].labels[j].language === this.translate.getDefaultLang()) {
                this.profile.emails[i].label = response.emails[i].labels[j].label;
              }
            }
          }
        }
        if (response && response.phoneNumbers) {
          for (let i = 0; i < response.phoneNumbers.length; i++) {
            for (let j = 0; j < response.phoneNumbers[i].labels.length; j++) {
              if (response.phoneNumbers[i].labels[j].language === this.translate.getDefaultLang()) {
                this.profile.phoneNumbers[i].label = response.phoneNumbers[i].labels[j].label;
              }
            }
          }
        }
      }
    });
    this.translate.onDefaultLangChange.subscribe(v => {
      this.lang = this.translate.getDefaultLang();
      if (this.profile && this.profile.descriptions) {
        for(let i = 0; i < this.profile.descriptions.length; i++) {
          if (this.profile.descriptions[i].language === this.translate.getDefaultLang()) {
            if(this.profile.descriptions[i].label &&  this.profile.descriptions[i].label.trim() != '') {
              this.profile.description = this.profile.descriptions[i].label;
            }
          }
        }
      }
      if (this.profile && this.profile.links) {
        for (let i = 0; i < this.profile.links.length; i++) {
          for (let j = 0; j < this.profile.links[i].labels.length; j++) {
            if (this.profile.links[i].labels[j].language === this.translate.getDefaultLang()) {
              this.profile.links[i].label = this.profile.links[i].labels[j].label;
            }
          }
        }
      }
      if (this.profile && this.profile.addresses) {
        for (let i = 0; i < this.profile.addresses.length; i++) {
          for (let j = 0; i < this.profile.addresses[i].label.length; j++) {
            if (this.profile.addresses[i].labels[j].language === this.translate.getDefaultLang()) {
              this.profile.addresses[i].label = this.profile.addresses[i].labels[j].label;
            }
          }
        }
      }
      if (this.profile && this.profile.emails) {
        for (let i = 0; i < this.profile.emails.length; i++) {
          for (let j = 0; i < this.profile.emails[i].label.length; j++) {
            if (this.profile.emails[i].labels[j].language === this.translate.getDefaultLang()) {
              this.profile.emails[i].label = this.profile.emails[i].labels[j].label;
            }
          }
        }
      }
      if (this.profile && this.profile.phoneNumbers) {
        for (let i = 0; i < this.profile.phoneNumbers.length; i++) {
          for (let j = 0; i < this.profile.phoneNumbers[i].label.length; j++) {
            if (this.profile.phoneNumbers[i].labels[j].language === this.translate.getDefaultLang()) {
              this.profile.phoneNumbers[i].label = this.profile.phoneNumbers[i].labels[j].label;
            }
          }
        }
      }
    });
  }
}
