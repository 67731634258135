import { Injectable } from '@angular/core';
import { forEach } from "@angular/router/src/utils/collection";

@Injectable({
  providedIn: 'root'
})
export class PipeFilterService {

  constructor() { }

  static filterUnique(list){
    var uniqueList = [];
    list.forEach(item => {
      if (uniqueList.indexOf(item) == -1){
        uniqueList.push(item);
      }
    });
    return uniqueList;
  }

  static filterOrderByAlphabet(list){
    list.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      });
    return list;
  }

  static replaceProvinceName(list){
    var provincesNames = [];
    list.forEach(item => {
      provincesNames.push(this.getProvinceName(item));
    });
    return provincesNames;
  }

  static getProvinceName(item : any){
    var newItem = {p : null, sp: null};
    var p = null;
    var sp = null;
    switch (item) {
      case 'QC':
      case 'Quebec':
        p = 'Quebec';
        sp = 'QC';
        break;
      case 'ON':
      case 'Ontario':
        p = 'Ontario';
        sp = 'ON';
        break;
      case 'NS':
      case 'Nova Scotia':
        p = 'Nova Scotia';
        sp = 'NS';
        break;
      case 'MB':
      case 'Manitoba':
        p = 'Manitoba';
        sp = 'MB';
        break;
      case 'BC':
      case 'British Columbia':
        p = 'British Columbia';
        sp = 'BC';
        break;
      case 'PE':
      case 'Prince Edward Island':
        p = 'Prince Edward Island';
        sp = 'PE';
        break;
      case 'SK':
      case 'Saskatchewan':
        p = 'Saskatchewan';
        sp = 'SK';
        break;
      case 'AB':
      case 'Alberta':
        p = 'Alberta';
        sp = 'AB';
        break;
      case 'NB':
      case 'New Brunswick':
        p = 'New Brunswick';
        sp = 'NB';
        break;
      case 'NL':
      case 'Newfoundland and Labrador':
        p = 'Newfoundland and Labrador';
        sp = 'NL';
        break;
      default :
        p = null;
        break;
    }
    newItem.p = p;
    newItem.sp = sp;
    return newItem;
  }

  static removeAccents(strAccents) {
    var strAccents = strAccents.normalize('NFC').split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents    = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž[]";
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz__";
    var toRemove = "´`¨ˆ˜";
    for (var i = 0; i < strAccentsLen; i++) {
      if (accents.indexOf(strAccents[i]) != -1) {
        strAccentsOut[i] = accentsOut.substr(accents.indexOf(strAccents[i]), 1);
      } else if(toRemove.indexOf(strAccents[i]) != -1) {
        // remove
      } else {
        strAccentsOut[i] = strAccents[i];
      }
    }
    return strAccentsOut.join('');
  }

  static filterCities(provinceSelected, provinceCityDictionary){
    let cities = [];
    if (provinceCityDictionary[provinceSelected]){
      cities = provinceCityDictionary[provinceSelected];
    }
    return cities;
  }
}
