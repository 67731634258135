import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  constructor(private http: HttpClient) { }

  getClientProfile(domain: string) {
    return this.http.get('./public/profiles/' + domain);
  }
}
