import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobApplicationService {

  constructor(private http: HttpClient) { }

  save(domain: string, jobApplication: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/jobApplication?ack=true', jobApplication);
  }

  saveNotification(domain: string, candidateId: string,  jobId: string, jobApplication: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidate/' + candidateId + '/acknowledge/' + jobId, jobApplication);
  }
}
