import {Component, Inject, OnInit} from '@angular/core';
import { BaseComponent } from '../base.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {CustomizationService} from '../../services/customization/customization.service';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent extends BaseComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private customizationService: CustomizationService,
              private translate: TranslateService,
              @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  style = BaseComponent.style ? BaseComponent.styleSpontaneousApplication : false;
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  styleColorText = BaseComponent.styleColorSpontaneousApplication ? BaseComponent.styleColorSpontaneousApplication : false;

  ngOnInit() {
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        if(this.profile.jobAlertTexts) {
          this.profile.jobAlertTexts.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.jobAlertText = item.label;
              }
            }
          );
        }
        if(this.profile.jobAlertTitles) {
          this.profile.jobAlertTitles.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.jobAlertTitle = item.label;
              }
            }
          );
        }
      }
    });

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
        if (response.backgroundSpontaneousApplicationId) {
          this.styleColorText = {
            color : response.spontaneousApplicationTextColor,
          };
          this.style = {
            background: 'url(' + this.baseUrl + '/files/' + response.backgroundSpontaneousApplicationId + ')',
            'background-repeat': 'no-repeat',
            'background-position-x': 'center'
          };
        }

        if(this.profile.spontaneousApplyTitles) {
          this.profile.spontaneousApplyTitles.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.spontaneousApplyTitle = item.label;
              }
            }
          );
        }

        if(this.profile.spontaneousApplyTexts) {
          this.profile.spontaneousApplyTexts.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.spontaneousApplyText = item.label;
              }
            }
          );
        }
      }
    });
  }

}
