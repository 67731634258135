import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, HttpResponse<any>> = new Map();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }
    if (req.params.get('cache')) {
      this.cache.delete(req.urlWithParams);
    }
    const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);
    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(req)
        .pipe(tap(event => {
          if (event instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, event.clone());
          }
        }));
    }
  }
}
