import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {CandidateService, Feedback} from '../../services/candidate/candidate.service';
import {BaseLimitedComponent} from '../base-limited.component';
import {CustomizationService} from '../../services/customization/customization.service';

@Component({
  selector: 'app-job-interview',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent extends BaseLimitedComponent implements OnInit {

  constructor(
    private candidateService: CandidateService,
    private customizationService: CustomizationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  private language: string;
  updating = false;
  candidate;
  justSaved: boolean;
  feedbackId: string;
  customization = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent : {};
  candidateId: string;
  passCode: string;
  action: string;
  feedback: Feedback;
  languageLevel;
  rehire;
  multipleSelectSettings = {};
  englishLevel;

  ngOnInit() {
    this.updating = true;

    this.language = this.translate.getDefaultLang();
    this.candidateId = this.getQueryParam(this.route, 'candidateId');
    this.feedbackId = this.getUrlParam(this.route, 'feedbackId');
    this.passCode = this.getQueryParam(this.route, 'passCode');
    this.action = this.getQueryParam(this.route, 'action') ? this.getQueryParam(this.route, 'action')[0] : undefined;
    this.feedback = {$$error : {}} as Feedback;

    this.translate.get(['UNSATISFACTORY', 'LOW',
      'GOOD', 'VERY_GOOD',
      'EXCELLENT', 'YES', 'NO']).subscribe(translations => {
      this.languageLevel = [
        { id  : 1, value : translations.UNSATISFACTORY},
        { id  : 2, value : translations.LOW},
        { id  : 3, value : translations.GOOD},
        { id  : 4, value : translations.VERY_GOOD},
        { id  : 5, value : translations.EXCELLENT},
      ];
      this.rehire = [
        { id  : 0, value : translations.YES},
        { id  : 1, value : translations.NO}
      ];
    });

    this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
      'UNSELECT_ALL']).subscribe(translations => {
      this.multipleSelectSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'id',
        textField: 'value',
        itemsShowLimit: 3,
        searchPlaceholderText: translations.SEARCH,
        selectAllText: translations.SELECT_ALL,
        unSelectAllText: translations.UNSELECT_ALL,
        allowSearchFilter: false
      };
    });

    this.candidateService.getFeedback(this.domain, this.candidateId, this.feedbackId, this.passCode).subscribe(
      (response: Feedback) => {
        this.feedback = response;
        this.feedback.$$error = {};
        this.feedback.$$content = {};
        if(this.feedback.status === 'SENT' && this.action === 'deny'){
          this.candidateService.denyFeedback(this.domain, this.candidateId, this.feedbackId, this.passCode, this.feedback).subscribe(
            (response: Feedback) => {
              this.feedback = response;
              this.feedback.$$error = {};
              this.updating = false;

            }, (error) => {
              console.error(error);
              this.updating = false;
            }
          );
        } else {
          this.updating = false;
          setTimeout(() => {
            const button = document.getElementById('startdatetimepickerbutton') as HTMLInputElement | null;
            if (button) {
              button.addEventListener('click', async () => {
                try {
                  // @ts-ignore
                  const input = document.getElementById('startdate') as HTMLInputElement | null;
                  input.type = 'date';
                  // @ts-ignore
                  await input.showPicker();
                  // A date picker is shown.
                } catch (error) {
                  // Use external library when this fails.
                }
              });
            }
          }, 2000);
          setTimeout(() => {
            const button = document.getElementById('enddatetimepickerbutton') as HTMLInputElement | null;
            if (button) {
              button.addEventListener('click', async () => {
                try {
                  // @ts-ignore
                  const input = document.getElementById('enddate') as HTMLInputElement | null;
                  input.type = 'date';
                  // @ts-ignore
                  await input.showPicker();
                  // A date picker is shown.
                } catch (error) {
                  // Use external library when this fails.
                }
              });
            }
          }, 2000);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this.translate.onDefaultLangChange.subscribe((val) => {
      this.language = this.translate.getDefaultLang();
      this.translate.get(['UNSATISFACTORY', 'LOW',
        'GOOD', 'VERY_GOOD',
        'EXCELLENT', 'YES', 'NO']).subscribe(translations => {
        this.languageLevel = [
          { id  : 1, value : translations.UNSATISFACTORY},
          { id  : 2, value : translations.LOW},
          { id  : 3, value : translations.GOOD},
          { id  : 4, value : translations.VERY_GOOD},
          { id  : 5, value : translations.EXCELLENT},
        ];
        this.rehire = [
          { id  : 0, value : translations.YES},
          { id  : 1, value : translations.NO}
        ];
      });

      this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
        'UNSELECT_ALL']).subscribe(translations => {
        this.multipleSelectSettings =  {
          enableCheckAll: false,
          singleSelection: true,
          closeDropDownOnSelection: true,
          idField: 'id',
          textField: 'value',
          itemsShowLimit: 3,
          searchPlaceholderText: translations.SEARCH,
          selectAllText: translations.SELECT_ALL,
          unSelectAllText: translations.UNSELECT_ALL,
          allowSearchFilter: false
        };
      });
    });
  }

  sendFeedback() {
    if (!this.hasError(this.feedback)) {
      this.updating = true;

      this.feedback.startDate = new Date(this.feedback.$$content.start);
      this.feedback.endDate = new Date(this.feedback.$$content.end);
      this.feedback.contactRelationship = this.feedback.$$content.contactRelationship;
      this.feedback.workQuality = this.feedback.$$content.workQuality[0].id;
      this.feedback.autonomy = this.feedback.$$content.autonomy[0].id;
      this.feedback.workUnderPressure = this.feedback.$$content.workUnderPressure[0].id;
      this.feedback.selfControl = this.feedback.$$content.selfControl[0].id;
      this.feedback.adaptation = this.feedback.$$content.adaptation[0].id;
      this.feedback.analysis = this.feedback.$$content.analysis[0].id;
      this.feedback.openMindedness = this.feedback.$$content.openMindedness[0].id;
      this.feedback.dynamism = this.feedback.$$content.dynamism[0].id;
      this.feedback.communication = this.feedback.$$content.communication[0].id;
      this.feedback.teamWork = this.feedback.$$content.teamWork[0].id;
      this.feedback.responsibility = this.feedback.$$content.responsibility[0].id;
      this.feedback.takingDecision = this.feedback.$$content.takingDecision[0].id;
      this.feedback.disponibility = this.feedback.$$content.disponibility[0].id;
      this.feedback.punctuality = this.feedback.$$content.punctuality[0].id;
      this.feedback.workCapacity = this.feedback.$$content.workCapacity[0].id;
      this.feedback.service = this.feedback.$$content.service[0].id;
      this.feedback.informatiqueHabilities = this.feedback.$$content.informatiqueHabilities[0].id;
      this.feedback.frenchLevel = this.feedback.$$content.frenchLevel[0].id;
      this.feedback.englishLevel = this.feedback.$$content.englishLevel[0].id;
      this.feedback.rehire = this.feedback.$$content.rehire[0].id === 0 ? true : false;
      this.feedback.reason = this.feedback.$$content.reason;
      this.feedback.comment = this.feedback.$$content.comment;
      this.candidateService.answerFeedback(this.domain, this.candidateId, this.feedbackId, this.passCode, this.feedback)
        .subscribe( (response: Feedback) => {
          this.feedback = response;
          this.updating = false;
        });
    }
  }

  private hasError(feedback: Feedback) {
    feedback.$$error = {};
    if (!feedback.$$content.contactRelationship) {
      feedback.$$error.contactRelationship = true;
    }
    if (!feedback.$$content.start) {
      feedback.$$error.startDate = true;
    }
    if (!feedback.$$content.start) {
      feedback.$$error.endDate = true;
    }
    if (!feedback.$$content.workQuality) {
      feedback.$$error.workQuality = true;
    }
    if (!feedback.$$content.autonomy) {
      feedback.$$error.autonomy = true;
    }
    if (!feedback.$$content.workUnderPressure) {
      feedback.$$error.workUnderPressure = true;
    }
    if (!feedback.$$content.selfControl) {
      feedback.$$error.selfControl = true;
    }
    if (!feedback.$$content.adaptation) {
      feedback.$$error.adaptation = true;
    }
    if (!feedback.$$content.analysis) {
      feedback.$$error.analysis = true;
    }
    if (!feedback.$$content.openMindedness) {
      feedback.$$error.openMindedness = true;
    }
    if (!feedback.$$content.dynamism) {
      feedback.$$error.dynamism = true;
    }
    if (!feedback.$$content.communication) {
      feedback.$$error.communication = true;
    }
    if (!feedback.$$content.teamWork) {
      feedback.$$error.teamWork = true;
    }
    if (!feedback.$$content.responsibility) {
      feedback.$$error.responsibility = true;
    }
    if (!feedback.$$content.takingDecision) {
      feedback.$$error.takingDecision = true;
    }
    if (!feedback.$$content.disponibility) {
      feedback.$$error.disponibility = true;
    }
    if (!feedback.$$content.punctuality) {
      feedback.$$error.punctuality = true;
    }
    if (!feedback.$$content.workCapacity) {
      feedback.$$error.workCapacity = true;
    }
    if (!feedback.$$content.service) {
      feedback.$$error.service = true;
    }
    if (!feedback.$$content.informatiqueHabilities) {
      feedback.$$error.informatiqueHabilities = true;
    }
    if (!feedback.$$content.frenchLevel) {
      feedback.$$error.frenchLevel = true;
    }
    if (!feedback.$$content.englishLevel) {
      feedback.$$error.englishLevel = true;
    }
    if (!feedback.$$content.rehire) {
      feedback.$$error.rehire = true;
    }
    if (!feedback.$$content.reason) {
      feedback.$$error.reason = true;
    }
    if (!feedback.$$content.comment) {
      feedback.$$error.comment = true;
    }
    return feedback.$$error.contactRelationship ||
      feedback.$$error.startDate ||
      feedback.$$error.endDate ||
      feedback.$$error.workQuality ||
      feedback.$$error.autonomy ||
      feedback.$$error.workUnderPressure ||
      feedback.$$error.selfControl ||
      feedback.$$error.adaptation ||
      feedback.$$error.analysis ||
      feedback.$$error.openMindedness ||
      feedback.$$error.dynamism ||
      feedback.$$error.communication ||
      feedback.$$error.teamWork ||
      feedback.$$error.responsibility ||
      feedback.$$error.takingDecision ||
      feedback.$$error.disponibility ||
      feedback.$$error.punctuality ||
      feedback.$$error.workCapacity ||
      feedback.$$error.service ||
      feedback.$$error.informatiqueHabilities ||
      feedback.$$error.frenchLevel ||
      feedback.$$error.englishLevel ||
      feedback.$$error.rehire ||
      feedback.$$error.notRehireReason ||
      feedback.$$error.comment;
  }
}
