import {Component, OnInit} from '@angular/core';
import { JobService } from '../../services/job/job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomizationService } from '../../services/customization/customization.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent extends BaseComponent implements OnInit {

  constructor(private jobService: JobService,
              private datePipe: DatePipe,
              private router: Router,
              private translate: TranslateService,
              private customizationService: CustomizationService,
              private route: ActivatedRoute) {
    super();
    this.loading = true;
    this.initializeProperties(window, route, this.translate);
  }

  private jobs: any[];
  private contractTypes = BaseComponent.customizationContent ? BaseComponent.customizationContent.contractTypes : [];
  private hierarchicalLevels = BaseComponent.customizationContent ? BaseComponent.customizationContent.hierarchicalLevels : [];
  private entities = BaseComponent.customizationContent ? BaseComponent.customizationContent.entities : [];
  private customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];
  private profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  private title: string;
  private totalPages: number[];
  private pageNumber = 0;
  private totalElements: number;
  private id: string;
  private language: string;
  clientSectionName: any;
  loading: boolean;
  now;

  ngOnInit() {
    this.now = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s');
    window.document.title = 'Job Board';

    this.translate.get('JOB_BOARD').subscribe((res: string) => {
      window.document.title = this.profile.name + ' - ' + res;
    });

    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.profile = response;
        this.translate.get('JOB_BOARD').subscribe((res: string) => {
          window.document.title = response.name + ' - ' + res;
        });
      }
      if (response) {
        this.customization = response;
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);

        // set client section name
        this.clientSectionName = {names: response.clientSectionName};
        this.setInformation(this.clientSectionName, 'names', 'label', this.translate);

        this.translate.onDefaultLangChange.subscribe((val) => {
          this.lang = this.translate.getDefaultLang();
          this.language = this.translate.getDefaultLang();

          this.setInformationList(this.contractTypes, 'values', 'label', this.translate);
          this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);
          this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
          this.setInformation(this.clientSectionName, 'names', 'label', this.translate);
          if (this.jobs) {
            this.setJobsInformation();
          }
        });
      }
    });
    this.route.queryParams.subscribe(params => {
      this.loading = true;
      if (params) {
        this.pageNumber = params.page ? params.page : 0;
        this.language = this.translate.getDefaultLang();
        this.jobService.search(this.domain, params).subscribe(
          (response: any) => {
            if (this.pageNumber > response.totalPages) {
              this.router.navigate(['/' + this.lang], {queryParams: {page  : 0}, queryParamsHandling: 'merge'});
            } else {
              this.jobs = response.content;
              this.totalElements = response.totalElements;
              this.totalPages = new Array<number>(Number(response.totalPages));
              this.setJobsInformation();
              this.loading = false;
            }
          });
      } else {
        this.jobService.getJobListByDomain(this.domain).subscribe(
          (response: any) => {
            this.jobs = response.content;
            this.totalElements = response.totalElements;
            this.totalPages = new Array<number>(Number(response.totalPages));
            this.setJobsInformation();
            this.loading = false;
          }
        );
      }
    });

  }

  private pageIncrement() {
    if (this.pageNumber < this.totalPages.length) {
      this.pageNumber++;
      this.paginationPressed(this.pageNumber);
    }
  }
  private pageDecrement() {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      this.paginationPressed(this.pageNumber);
    }
  }
  private paginationPressed(count: number) {
    this.pageNumber = count;
    this.router.navigate(['/' + this.lang], {queryParams: {page  : this.pageNumber}, queryParamsHandling: 'merge'});
  }


  private setJobsInformation() {
    this.jobs.forEach(job => {
        if (job !== undefined) {
          job.clientPhotoUrl = BaseComponent.baseUrlContent + '/files/' + job.clientPhotoId;
          if (this.translate.getDefaultLang() !== undefined) {
            if (job.clientNames !== undefined && job.clientNames !== null) {
              for (const clientName of job.clientNames) {
                if (clientName.language === this.translate.getDefaultLang()) {
                  job.clientName = clientName.name;
                }
              }
            }
            if (job.localizedJobs !== undefined && job.localizedJobs !== null) {
              for (const localizedJob of job.localizedJobs) {
                if (localizedJob.language === this.translate.getDefaultLang()) {
                  job.title = localizedJob.title;
                  job.shortDescription = localizedJob.shortDescription;
                  job.fullDescription = localizedJob.fullDescription;
                }
              }
            }
          }
        }
      }
    );
  }
}
