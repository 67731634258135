import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CandidateAccountService {

  constructor(private http: HttpClient) { }

  get(domain: string, candidateId: string, passCode: string): Observable<any> {
    return this.http.get('./public/domain/' + domain + '/candidateAccount/' + candidateId + '?passCode=' + passCode);
  }

  save(domain: string, candidate: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidateAccount', candidate);
  }

  validate(domain: string, candidateId: any, passCode: string): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidateAccount/' + candidateId + '/validate' + '?passCode=' + passCode, {});
  }

  resendEmail(domain: string, candidate: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidateAccount/' + candidate.id + '/resendCandidateAccountLink', candidate);
  }

  update(domain: string, candidate: any, passCode: string): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidateAccount/' + candidate.id + '?passCode=' + passCode, candidate);
  }

}
