import {Component, Inject, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {TranslateService} from '@ngx-translate/core';
import {CandidateAccount} from '../../models/candidate-account.model';
import {PipeFilterService} from '../../services/utils/pipeFilter.service';
import {ClientService} from '../../services/client/client.service';
import {ActivatedRoute} from '@angular/router';
import {JobTemplateService} from '../../services/job-template/job-template.service';
import {Candidate} from '../../models/candidate.model';
import {CandidateAccountService} from '../../services/candidateAccount/candidateAccount.service';
import {JobAlertSubscription} from '../../models/job-alert-subscription.model';
import {HttpClient} from '@angular/common/http';
import {CustomizationService} from '../../services/customization/customization.service';

@Component({
  selector: 'job-alert',
  templateUrl: './job-alert.component.html',
  styleUrls: ['./job-alert.component.scss'],
})
export class JobAlertComponent extends BaseComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private customizationService: CustomizationService,
    private candidateAccountService: CandidateAccountService,
    private jobTemplateService: JobTemplateService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }
  citiesList = [];
  selectedCities = [];
  citiesSettings = {};
  jobTemplateList = [];
  selectedJobTemplate = [];
  jobTemplateSettings = {};

  candidate;
  private provinces;
  private cities;
  duplicated = false;
  error = false;
  applying;
  validCountry;
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  style = BaseComponent.styleJobAlert ? BaseComponent.styleJobAlert : false;
  styleColorText = BaseComponent.styleColorJobAlert ? BaseComponent.styleColorJobAlert : false;
  private provinceCityDictionary = [];
  private jobTemplates: any[];

  ngOnInit() {
    this.applying = false;
    this.validCountry = true;
    this.candidate = new CandidateAccount();
    this.candidate.email = this.route.snapshot.queryParamMap.get('email');
    this.candidate.jobTemplateId = '';
    this.candidate.province = '';
    this.candidate.city = '';

    if (this.customization.hiddenCareerPortalProperties) {
      this.validateCountry();
    } else {
      this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
        if (response) {
          this.customization = response;
          this.validateCountry();

          if(this.profile.jobAlertTexts) {
            this.profile.jobAlertTexts.forEach(item => {
                if (item.language === this.translate.getDefaultLang()) {
                  this.profile.jobAlertText = item.label;
                }
              }
            );
          }
          if(this.profile.jobAlertTitles) {
            this.profile.jobAlertTitles.forEach(item => {
                if (item.language === this.translate.getDefaultLang()) {
                  this.profile.jobAlertTitle = item.label;
                }
              }
            );
          }
        }
      });
    }

    this.provinces = [];
    this.cities = [];
    this.clientService.getClientByDomain(this.domain).subscribe(response => {
      if (response && response._embedded) {
        const clients = response._embedded.clients;
        clients.forEach(client => {
          if (client.standardAddress) {
            this.provinces.push(client.standardAddress.province);
            this.cities.push(client.standardAddress.city);
            if (!this.provinceCityDictionary[client.standardAddress.province]) {
              this.provinceCityDictionary[client.standardAddress.province] = [];
            }
            this.provinceCityDictionary[client.standardAddress.province].push(client.standardAddress.city);
          }
        });
        this.provinces = PipeFilterService.filterUnique(this.provinces).filter(v => v !== '' && v != null);
        this.provinces = PipeFilterService.filterOrderByAlphabet(this.provinces);
        this.provinces = PipeFilterService.replaceProvinceName(this.provinces);

        this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
        this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);

        this.cities.forEach(element => {
          this.citiesList.push({ item_id: element, item_text: element });
        });
      }
    });
    this.jobTemplateService.getJobTemplate(this.domain).subscribe((response: any) => {

      // set job templates
      this.jobTemplates = response;
      this.setInformationList(this.jobTemplates, 'localizedJobs', 'title', this.translate);

    });

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
        if(this.profile.jobAlertTexts) {
          this.profile.jobAlertTexts.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.jobAlertText = item.label;
              }
            }
          );
        }
        if(this.profile.jobAlertTitles) {
          this.profile.jobAlertTitles.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.jobAlertTitle = item.label;
              }
            }
          );
        }

        if (response.backgroundJobAlertId) {
          this.styleColorText = {
            color : response.jobAlertTextColor
          };
          this.style = {
            color : response.jobAlertTextColor,
            background: 'url(' + this.baseUrl + '/files/' + response.backgroundJobAlertId + ')',
            'background-repeat': 'no-repeat',
            'background-position-x': 'center'
          };
        }
      }
    });

    this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
      'UNSELECT_ALL']).subscribe(translations => {
      this.citiesSettings =  {
        enableCheckAll: false,
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.jobTemplateSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'id',
        textField: 'title',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });
  }

  validateCountry() {
    if (this.customization.hiddenCareerPortalProperties.countriesCanApply) {
      this.http.get('https://ipinfo.io?token=92f557f01adab8').subscribe((response: any) => {
        if (response) {
          const country = response.country;
          // var ipInfo = response;

          if (this.customization.hiddenCareerPortalProperties.countriesCanApply.indexOf(country) > -1) {
            this.validCountry = true;
          } else {
            this.validCountry = false;
          }
        }
      });
    }
  }

  hasError() {
    this.candidate.validate();
    if (this.selectedJobTemplate === undefined
        || this.selectedJobTemplate.length === 0) {
      this.candidate.$$error.jobTemplateId = this.translate.get('ENTER_VALUE');
    }
    if(this.cities.length === 1) {
      this.selectedCities = [{item_id: this.cities[0], item_text: this.cities[0]}];
    }
    if (this.selectedCities === undefined
        || this.selectedCities.length === 0) {
      this.candidate.$$error.city = this.translate.get('ENTER_VALUE');
    }
    return this.candidate.$$error.jobTemplateId !== undefined
      || this.candidate.$$error.city !== undefined
      || this.candidate.hasError();
  }

  createAccount() {
    this.applying = true;
    if (!this.hasError() ) {
      this.candidate.preferredLanguage = this.translate.getDefaultLang() !== undefined ? this.translate.getDefaultLang() : 'en';
      const newJobAlertSubscription = new JobAlertSubscription();
      newJobAlertSubscription.jobTemplateId = this.selectedJobTemplate[0].id;
      newJobAlertSubscription.cities = new Array();
      newJobAlertSubscription.cities = this.selectedCities.map(city => city.item_id);
      this.candidate.jobAlertSubscriptions = new Array();
      this.candidate.jobAlertSubscriptions.push(newJobAlertSubscription);
      this.candidateAccountService.save(this.domain, this.candidate).subscribe(resource => {
        this.candidate = new Candidate().deserialize(resource);
        this.applying = false;
      }, response => {
        // There was an http error returned
        if (response.status == '409') {
          this.candidate = new Candidate().deserialize(response.error);
          this.duplicated = true;
          this.applying = false;
        } else {
          this.error = true;
          this.applying = false;
        }
      });
    } else {
      this.applying = false;
    }
  }

  resendLink() {
    this.applying = true;
    this.duplicated = false;
    this.candidateAccountService.resendEmail(this.domain, this.candidate).subscribe(resource => {
      this.candidate = new Candidate().deserialize(resource);
      this.applying = false;
    }, response => {
        this.error = true;
        this.applying = false;
    });
  }

  retry() {
    this.applying = false;
    this.duplicated = false;
    this.error = false;

  }

}
