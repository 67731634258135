import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== undefined) {
      switch (value) {
        case 'FR':
        case 'fr':
          return 'Français';
          break;
        case 'EN':
        case 'en':
          return 'English';
          break;
        case 'ES':
        case 'es':
          return 'Spanish';
          break;
        default:
          return value;
      }
    } else {
      return '';
    }
  }

}
