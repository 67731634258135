import {Component, Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Profile } from './Model';

@Component({
  selector: 'app-base',
  template: '<router-outlet></router-outlet>'
})
export class BaseLimitedComponent {

  constructor() {}
  @Inject(DOCUMENT) private static document: Document;
  protected static customization = new Subject();
  protected static profile  = new Subject();
  protected static baseUrl  = new Subject();
  protected static customizationLoaded = false;
  protected static customizationContent;
  protected static profileContent;
  protected static style;
  protected static styleJobAlert;
  protected static styleSpontaneousApplication;
  protected static styleColor;
  protected static styleColorJobAlert;
  protected static styleColorSpontaneousApplication;
  protected static baseUrlContent;
  protected lang: string;
  protected domain: string;

  protected static initializeCustomization(customizationService, route: ActivatedRoute, domain: string) {
    if (domain) {
      if (!BaseLimitedComponent.customizationContent || !BaseLimitedComponent.customizationLoaded) {
        BaseLimitedComponent.customizationLoaded = true;
        // should be used to set the social links
        customizationService.getClientProfile(domain).subscribe((response: Profile) => {
          BaseLimitedComponent.baseUrl.next(environment.apiUrl);
          BaseLimitedComponent.baseUrlContent = environment.apiUrl;
          if (response.logoId) {
            response.logoUrl = BaseLimitedComponent.baseUrlContent + '/files/' + response.logoId;
          }
          if (response.reverseLogoId) {
            response.reverseLogoUrl = BaseLimitedComponent.baseUrlContent + '/files/' + response.reverseLogoId;
          }
          BaseLimitedComponent.profile.next(response);
          BaseLimitedComponent.profileContent = response;
          if (response.backgroundId) {
            BaseLimitedComponent.styleColor = {
              color : response.backgroundsTextColor,
            };
            BaseLimitedComponent.style = {
              background: 'url(' + BaseLimitedComponent.baseUrlContent + '/files/' + response.backgroundId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if (response.backgroundJobAlertId) {
            BaseLimitedComponent.styleColorJobAlert = {
              color : response.jobAlertTextColor,
            };
            BaseLimitedComponent.styleJobAlert = {
              background: 'url(' + BaseLimitedComponent.baseUrlContent + '/files/' + response.backgroundJobAlertId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if (response.backgroundSpontaneousApplicationId) {
            BaseLimitedComponent.styleColorSpontaneousApplication = {
              color : response.jobAlertTextColor,
            };
            BaseLimitedComponent.styleSpontaneousApplication = {
              background: 'url(' + BaseLimitedComponent.baseUrlContent + '/files/' + response.backgroundSpontaneousApplicationId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if (response.faviconId) {
            // Set the favicon once we save it in the BE
            const link = document.querySelector('link[rel*=\'icon\']') as HTMLLinkElement || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = BaseLimitedComponent.baseUrlContent + '/files/' + response.faviconId;
            // document.getElementsByTagName('head')[0].appendChild(link);
          }

          response.contractTypes.sort(function ( a, b ) {
            if ( a.orderNo < b.orderNo ) {
              return -1;
            }
            if ( a.orderNo > b.orderNo ) {
              return 1;
            }
            return 0;
          });
          BaseLimitedComponent.customization.next(response);
          BaseLimitedComponent.customizationContent = response;
          BaseLimitedComponent.customizationContent = response;
        });
      } else if (!BaseLimitedComponent.customization && BaseLimitedComponent.customizationLoaded) {
        BaseLimitedComponent.customization.next(BaseLimitedComponent.customizationContent);
      } else if (BaseLimitedComponent.customizationContent) {
        BaseLimitedComponent.customization.next(BaseLimitedComponent.customizationContent);
      }
    }
  }

  ngOnInit(): void {
  }

  protected static setDynamicStyle(domain: string) {
    //Set domain style
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = environment.apiUrl + '/public/profiles/' + domain + '.css';
    head.appendChild(style);


  }

  protected initializeProperties(window, route, translate) {
    this.domain = this.getDomain(window);
    this.lang = this.getLanguage(route);
    if (this.lang !== undefined) {
      translate.setDefaultLang(this.lang);
    }
  }

  protected getLanguage(route) {
    let lang = 'en';
    if (route.params) {
      route.params.subscribe(val => {
        lang = val.lang;
      });
    }
    return lang;
  }

  protected getDomain(window) {
      if (window.location.hostname.split('.').length > 0) {
        if (window.location.hostname === 'localhost' || window.location.hostname === 'private.jobs.nextal.com') {
          return new URLSearchParams(window.location.search).get("domain") || 'aubainerie';
        } else {
          return window.location.hostname.split('.')[0];
        }
      }
      return new URLSearchParams(window.location.search).get("domain") || 'aubainerie';
  }

  protected getUrlParam(router: ActivatedRoute, key) {
    let param;
    router.params.subscribe(val => {
      param = val[key];
    });
    return param;
  }

  protected getQueryParam(route: ActivatedRoute, key) {
    let param;
    route.queryParamMap
      .subscribe((params) => {
          param = params.getAll(key);
        }
      );
    return param;
  }

  // set information based on current language for item
  protected setInformation(item: any, field, newField, translate) {
    if (!item.hasOwnProperty('on')) {
      item.on = false;
    }
    if (item !== undefined) {
      if (translate.getDefaultLang() !== undefined) {
        if (item[field] !== undefined && item[field] !== null) {
          for (let i = 0; i < item[field].length; i++) {
            const fieldItem = item[field][i];
            if (fieldItem.language === translate.getDefaultLang()) {
              if (newField instanceof Array) {
                newField.forEach(value => {
                  item[value] = fieldItem[value];
                });
              } else {
                item[newField] = fieldItem[newField];
              }
            }
          }
        }
      }
    }
  }

  // set information based on current language for list
  protected setInformationList(list: any[], field, newField, translate) {
    if(list) {
      list.forEach(item => {
        this.setInformation(item, field, newField, translate);
      });
    }
  }

  openPage(router, page, translate) {
    if (page === '') {
      router.navigate(['/' + translate.getDefaultLang()]);
    } else {
      router.navigate(['/' + translate.getDefaultLang() + '/' + page]);
    }
  }

  protected openPageWithQuery(router, page, translate, queryKey, queryValue) {
    if (page === '') {
      router.navigate(['/' + translate.getDefaultLang()], {queryParams: {[queryKey] : queryValue}, queryParamsHandling: 'merge'});
    } else {
      router.navigate(['/' + translate.getDefaultLang() + '/' + page], {queryParams: {[queryKey] : queryValue}, queryParamsHandling: 'merge'});
    }
  }
}
