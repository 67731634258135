import { Deserializable } from "./deserializable.model";

export class Employer implements Deserializable {

  public id;
  public name;
  public from;
  public to;
  public description;
  public $$error = {name: undefined, to: undefined, from: undefined, description: undefined};


  deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id;
      }
      if (input.name) {
        this.name = input.name;
      }
      if (input.from) {
        this.from = input.from;
      }
      if (input.to) {
        this.to = input.to;
      }
      if (input.description) {
        this.description = input.description;
      }
    }
    return this;
  }

  hasError() {
    this.validate();
    return this.$$error.name !== undefined
      || this.$$error.to !== undefined
      || this.$$error.from !== undefined
      || this.$$error.description !== undefined;
  };

  validate() {
    this.$$error = {name: undefined, to: undefined, from: undefined, description: undefined};
    if (this.name === undefined
      || this.name === "") {
      this.$$error.name = "Please provide a name";
    }
    if (this.to === undefined
      || this.to === "") {
      this.$$error.to = "Please provide an end date";
    }
    if (this.from === undefined
      || this.from === "") {
      this.$$error.from = "Please provide a starting date";
    }
    if (this.description === undefined
      || this.description === "") {
      this.$$error.description = "Please provide a description";
    }
  };
}
