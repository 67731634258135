import {Deserializable} from './deserializable.model';
import {StandardAddress} from './standard-address.model';
import {JobAlertSubscription} from './job-alert-subscription.model';

export class CandidateAccount implements Deserializable {

  public id;
  public creationDate;
  public updatedDate;
  public version;
  public creationUserId;
  public lastUpdateUserId;
  public externalReferenceId;
  public candidateId;
  public passCode;
  public validated;
  public firstName;
  public lastName;
  public email;
  public phoneNumber;
  public preferredLanguage;
  public standardAddress;
  public geocode;
  public jobAlertSubscriptions;
  public jobTemplate;
  public province;
  public city;

  public $$error = {firstName: undefined, lastName: undefined,
    email: undefined, phoneNumber: undefined, preferredLanguage: undefined,
    jobAlertSubscriptions: undefined,
    jobTemplate: undefined, province: undefined, city: undefined
  };

  deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id;
      }
      if (input.creationDate) {
        this.creationDate = input.creationDate;
      }
      if (input.updatedDate) {
        this.updatedDate = input.updatedDate;
      }
      this.version = 0;
      if (input.version) {
        this.version = input.idversion;
      }
      if (input.creationUserId) {
        this.creationUserId = input.creationUserId;
      }
      if (input.lastUpdateUserId) {
        this.lastUpdateUserId = input.lastUpdateUserId;
      }
      if (input.externalReferenceId) {
        this.externalReferenceId = input.externalReferenceId;
      }
      if (input.candidateId) {
        this.candidateId = input.candidateId;
      }
      if (input.firstName) {
        this.firstName = input.firstName;
      }
      if (input.lastName) {
        this.lastName = input.lastName;
      }
      if (input.validated) {
        this.validated = input.validated;
      }
      if (input.email) {
        this.email = input.email;
      }
      if (input.phoneNumber) {
        this.phoneNumber = input.phoneNumber;
      }
      if (input.preferredLanguage) {
        this.preferredLanguage = input.preferredLanguage;
      }
      if (input.standardAddress) {
        this.standardAddress = new StandardAddress().deserialize(input.standardAddress);
      }
      this.jobAlertSubscriptions = new Array();
      if (input.jobAlertSubscriptions) {
        for (let i = 0; i < input.jobAlertSubscriptions.length; i++) {
          this.jobAlertSubscriptions.push(new JobAlertSubscription().deserialize(input.jobAlertSubscriptions[i]));
        }
      }
      this.geocode = new Array();
    }
    return this;
  }

  validatePhoneNumber(phoneNumber) {
    const phoneNumberPattern = /^\d{10}$/;
    if (phoneNumber !== undefined) {
      phoneNumber = phoneNumber.replace(/ /g, '')
        .replace(/-/g, '')
        .replace(/\./g, '');
      return phoneNumber.match(phoneNumberPattern);
    } else {
      return false;
    }

  }

  validateName(name) {
    if (name !== undefined) {
      return name.indexOf(' ') > -1;
    } else {
      return false;
    }

  }

  validateEmail(email) {
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  public hasError() {
    this.validate();
    return this.$$error.firstName !== undefined
      || this.$$error.lastName !== undefined
      || this.$$error.phoneNumber !== undefined
      || this.$$error.preferredLanguage !== undefined
      || this.$$error.email !== undefined
      || this.$$error.jobAlertSubscriptions !== undefined;
  }

  validate() {
    this.$$error = {firstName: undefined, lastName: undefined,
      email: undefined, phoneNumber: undefined, preferredLanguage: undefined,
      jobAlertSubscriptions: undefined,
      jobTemplate: undefined, province: undefined, city: undefined
    };
    if (!this.firstName || this.firstName === '') {
      this.$$error.firstName = 'ENTER_FIRSTNAME';
    }
    if (!this.lastName || this.lastName === '') {
      this.$$error.lastName = 'ENTER_LASTNAME';
    }
    if (this.email === undefined || this.email === '' || !this.validateEmail(this.email)) {
      this.$$error.email = 'ENTER_EMAIL';
    }
    if (this.phoneNumber === undefined || this.phoneNumber === '' || !this.validatePhoneNumber(this.phoneNumber)) {
      this.$$error.phoneNumber = 'ENTER_PHONE_NUMBER';
    }
    if (this.jobAlertSubscriptions) {
      for (let i = 0; i < this.jobAlertSubscriptions.length; i++) {
        if (this.jobAlertSubscriptions[i].hasError()) {
          this.$$error.jobAlertSubscriptions = 'ENTER_SUBSCRIPTION';
          break;
        }
      }
    }
  }


}
