import {Component, Inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Profile, ReverserLogo, Background } from './Model';

@Component({
  selector: 'app-base',
  template: '<router-outlet></router-outlet>'
})
export class BaseComponent {
  constructor() {}

  @Inject(DOCUMENT) private static document: Document;
  protected static customization = new Subject();
  protected static profile  = new Subject();
  protected static baseUrl  = new Subject();
  protected static customizationLoaded = false;
  protected static customizationContent;
  protected static profileContent;
  protected static style;
  protected static styleJobAlert;
  protected static styleSpontaneousApplication;
  protected static styleColor;
  protected static styleColorJobAlert;
  protected static styleColorSpontaneousApplication;
  protected static baseUrlContent;
  public iframe: boolean;
  protected lang: string;
  protected domain: string;

  protected static initializeCustomization(customizationService, route: ActivatedRoute, domain: string) {
    if (domain) {
      if (!BaseComponent.customizationContent || !BaseComponent.customizationLoaded) {
        BaseComponent.customizationLoaded = true;
        // should be used to set the social links
        customizationService.getClientProfile(domain).subscribe((response: Profile) => {
          let lang = this.getStaticLanguage(route);
          BaseComponent.baseUrl.next(environment.apiUrl);
          BaseComponent.baseUrlContent = environment.apiUrl;
          if(response.logos){
            let logo: ReverserLogo = response.logos.find((element) => element.language == lang);
            if(logo) {
              response.logoUrl = BaseComponent.baseUrlContent + '/files/' + logo.photoId;
            }
          }
          if (!response.logoUrl && response.logoId) {
            response.logoUrl = BaseComponent.baseUrlContent + '/files/' + response.logoId;
          }
          if(response.reverseLogos){
            let reverseLogo: ReverserLogo = response.reverseLogos.find((element) => element.language == lang);
            if(reverseLogo) {
              response.reverseLogoUrl = BaseComponent.baseUrlContent + '/files/' + reverseLogo.photoId;
            }
          }
          if (!response.reverseLogoUrl && response.reverseLogoId) {
            response.reverseLogoUrl = BaseComponent.baseUrlContent + '/files/' + response.reverseLogoId;
          }
          if(response.backgrounds){
            let lang = this.getStaticLanguage(route);
            let background: Background = response.backgrounds.find((element) => element.language == lang);
            if(background) {
              response.backgroundId = background.photoId;
              if(background.textColor) {
                response.backgroundsTextColor = background.textColor;
              }
            }
          }
          if (response.backgroundId) {
            BaseComponent.styleColor = {
              color : response.backgroundsTextColor,
            };
            BaseComponent.style = {
              background: 'url(' + BaseComponent.baseUrlContent + '/files/' + response.backgroundId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if(response.jobAlertBackgrounds){
            let lang = this.getStaticLanguage(route);
            let background: Background = response.jobAlertBackgrounds.find((element) => element.language == lang);
            if(background) {
              response.backgroundJobAlertId = background.photoId;
              if(background.textColor) {
                response.jobAlertTextColor = background.textColor;
              }
            }
          }
          if (response.backgroundJobAlertId) {
            BaseComponent.styleColorJobAlert = {
              color : response.jobAlertTextColor,
            };
            BaseComponent.styleJobAlert = {
              background: 'url(' + BaseComponent.baseUrlContent + '/files/' + response.backgroundJobAlertId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if(response.spontaneousApplicationBackgrounds){
            let lang = this.getStaticLanguage(route);
            let background: Background = response.spontaneousApplicationBackgrounds.find((element) => element.language == lang);
            if(background) {
              response.backgroundSpontaneousApplicationId = background.photoId;
              if(background.textColor) {
                response.jobAlertTextColor = background.textColor;
              }
            }
          }
          if (response.backgroundSpontaneousApplicationId) {
            BaseComponent.styleColorSpontaneousApplication = {
              color : response.jobAlertTextColor,
            };
            BaseComponent.styleSpontaneousApplication = {
              background: 'url(' + BaseComponent.baseUrlContent + '/files/' + response.backgroundSpontaneousApplicationId + ')',
              'background-repeat': 'no-repeat',
              'background-position-x': 'center'};
          }
          if(response.favicons){
            let lang = this.getStaticLanguage(route);
            let background: ReverserLogo = response.favicons.find((element) => element.language == lang);
            if(background) {
              response.faviconId = background.photoId;
            }
          }
          if (response.faviconId) {
            // Set the favicon once we save it in the BE
            const link = document.querySelector('link[rel*=\'icon\']') as HTMLLinkElement || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = BaseComponent.baseUrlContent + '/files/' + response.faviconId;
            // document.getElementsByTagName('head')[0].appendChild(link);
          }
          BaseComponent.profile.next(response);
          BaseComponent.profileContent = response;

          response.contractTypes.sort(function ( a, b ) {
            if ( a.orderNo < b.orderNo ) {
              return -1;
            }
            if ( a.orderNo > b.orderNo ) {
              return 1;
            }
            return 0;
          });
          BaseComponent.customization.next(response);
          BaseComponent.customizationContent = response;
          BaseComponent.customizationContent = response;
        });
      } else if (!BaseComponent.customization && BaseComponent.customizationLoaded) {
        BaseComponent.customization.next(BaseComponent.customizationContent);
      } else if (BaseComponent.customizationContent) {
        BaseComponent.customization.next(BaseComponent.customizationContent);
      }
    }
  }

  ngOnInit(): void {

  }

  protected static setDynamicStyle(domain: string) {
    //Set domain style
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = environment.apiUrl + '/public/profiles/' + domain + '.css';
    head.appendChild(style);
  }

  protected initializeProperties(window, route, translate) {
    this.domain = this.getDomain(window);
    this.lang = this.getLanguage(route);
    if (this.lang !== undefined) {
      translate.setDefaultLang(this.lang);
    }

    route.queryParams
      .subscribe(params => {
          this.iframe = params.iframe;
        }
      );
  }

  protected getLanguage(route) {
    let lang = 'en';
    if (route.params) {
      route.params.subscribe(val => {
        lang = val.lang;
      });
    }
    return lang;
  }

  protected static getStaticLanguage(route) {
    let lang = 'en';
    if (route.params) {
      route.params.subscribe(val => {
        lang = val.lang;
      });
    }
    return lang;
  }

  protected getDomain(window) {
      if (window.location.hostname.split('.').length > 0) {
        if (window.location.hostname === 'localhost' || window.location.hostname === 'private.jobs.nextal.com') {
          return new URLSearchParams(window.location.search).get("domain") || 'aubainerie';
        } else {
          return window.location.hostname.split('.')[0];
        }
      }
      return new URLSearchParams(window.location.search).get("domain") || 'aubainerie';
  }

  protected getUrlParam(router: ActivatedRoute, key) {
    let param;
    router.params.subscribe(val => {
      param = val[key];
    });
    return param;
  }

  protected getQueryParam(route: ActivatedRoute, key) {
    let param;
    route.queryParamMap
      .subscribe((params) => {
          param = params.getAll(key);
        }
      );
    return param;
  }

  // set information based on current language for item
  protected setInformation(item: any, field, newField, translate) {
    if (!item.hasOwnProperty('on')) {
      item.on = false;
    }
    if (item !== undefined) {
      if (translate.getDefaultLang() !== undefined) {
        if (item[field] !== undefined && item[field] !== null) {
          for (let i = 0; i < item[field].length; i++) {
            const fieldItem = item[field][i];
            if (fieldItem.language === translate.getDefaultLang()) {
              if (newField instanceof Array) {
                newField.forEach(value => {
                  item[value] = fieldItem[value];
                });
              } else {
                item[newField] = fieldItem[newField];
              }
            }
          }
        }
      }
    }
  }

  // set information based on current language for list
  protected setInformationList(list: any[], field, newField, translate) {
    if(list) {
      list.forEach(item => {
        this.setInformation(item, field, newField, translate);
      });
    }
  }



  // set information based on current language for item
  protected setInformationRemoveAccent(item: any, field, newField) {
    if (item[field] !== undefined && item[field] !== null) {
        item[newField] = this.removeAccents(item[field]);
    }
  }

  // set information based on current language for list
  protected setInformationRemoveAccentList(list: any[], field, newField) {
    if(list) {
      list.forEach(item => {
        this.setInformationRemoveAccent(item, field, newField);
      });
    }
  }

  private removeAccents(strAccents) {
    var strAccents = strAccents.normalize('NFC').split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents    = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž[]";
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz__";
    var toRemove = "´`¨ˆ˜";
    for (var i = 0; i < strAccentsLen; i++) {
      if (accents.indexOf(strAccents[i]) != -1) {
        strAccentsOut[i] = accentsOut.substr(accents.indexOf(strAccents[i]), 1);
      } else if(toRemove.indexOf(strAccents[i]) != -1) {
        // remove
      } else {
        strAccentsOut[i] = strAccents[i];
      }
    }
    return strAccentsOut.join('');
  }

  openPage(router, page, translate) {
      this.openPageWithQuery(router, page, translate, "iframe", this.iframe);
  }

  protected openPageWithQuery(router, page, translate, queryKey, queryValue) {
    if (page === '') {
      router.navigate(['/' + translate.getDefaultLang()], {queryParams: {[queryKey] : queryValue}, queryParamsHandling: 'merge'});
    } else {
      router.navigate(['/' + translate.getDefaultLang() + '/' + page], {queryParams: {[queryKey] : queryValue}, queryParamsHandling: 'merge'});
    }
  }
}
