import { Deserializable } from "./deserializable.model";
import { PositionMinimal } from "./position-minimal.model";
import { Technology } from "./technology.model";
import { Employer } from "./employer.model";

export class Candidate implements Deserializable {

  public id;
  public referenceFrom;
  public photoId;
  public range;
  public status;
  public name;
  public curriculum;
  public description;
  public actualPosition = {text : ""};
  public actualSalary;
  public requestedPosition;
  public requestedSalary;
  public experience;
  public englishLevel;
  public frenchLevel;
  public email;
  public mails;
  public phoneNumber;
  public address;
  public jobApplications;
  public facebook;
  public linkedin;
  public twitter;
  public monster;
  public viadeo;
  public newCandidate;
  public google;
  public technologies = [];
  public employers = [];
  public files = [];

  public $$error = {name: undefined, email: undefined, files: undefined, phoneNumber: undefined, city: undefined, province: undefined};

  deserialize(input: any): this {
    this.files = new Array();
    if (input) {
      if (input.id) {
        this.id = input.id;
      }
      if (input.referenceFrom) {
        this.referenceFrom = input.referenceFrom;
      }
      if (input.photoId) {
        this.photoId = input.photoId;
      }
      if (input.range) {
        this.range = input.range;
      } else {
        this.range = 3;
      }
      if (input.status) {
        this.status = input.status;
      }
      if (input.name) {
        this.name = input.name;
      }
      if (input.curriculum) {
        this.curriculum = input.curriculum;
      }
      if (input.description) {
        this.description = input.description;
      }
//            this.curriculum = config.curriculum || "";
      if (input.actualPosition) {
        this.actualPosition = new PositionMinimal().deserialize(input.actualPosition);
      }
      if (input.actualSalary) {
        this.actualSalary = input.actualSalary;
      }
      if (input.requestedPosition) {
        this.requestedPosition = new PositionMinimal().deserialize(input.requestedPosition);
      }
      if (input.requestedSalary) {
        this.requestedSalary = input.requestedSalary;
      }
      if (input.experience) {
        this.experience = input.experience;
      }
      if (input.frenchLevel) {
        this.frenchLevel = input.frenchLevel;
      }
      if (input.englishLevel) {
        this.englishLevel = input.englishLevel;
      }
      if (input.email) {
        this.email = input.email;
      }
      if (input.mails) {
        this.mails = input.mails;
      }
      if (input.phoneNumber) {
        this.phoneNumber = input.phoneNumber;
      }
      if (input.address) {
        this.address = input.address;
      }
      if (input.jobApplications) {
        this.jobApplications = input.jobApplications;
      }
      if (input.facebook) {
        this.facebook = input.facebook;
      }
      if (input.linkedin) {
        this.linkedin = input.linkedin;
      }
      if (input.monster) {
        this.monster = input.monster;
      }
      if (input.twitter) {
        this.twitter = input.twitter;
      }
      if (input.google) {
        this.google = input.google;
      }
      if (input.viadeo) {
        this.viadeo = input.viadeo;
      }
      if (input.newCandidate) {
        this.newCandidate = input.newCandidate;
      }
      this.technologies = new Array();
      if (input.technologies) {
        for (var i = 0; i < input.technologies.length; i++) {
          this.technologies.push(new Technology().deserialize(input.technologies[i]));
        }
      }
      this.employers = new Array();
      if (input.employers) {
        for (var i = 0; i < input.employers.length; i++) {
          this.employers.push(new Employer().deserialize(input.employers[i]));
        }
      }
      if (input.files) {
        for (var i = 0; i < input.files.length; i++) {
          this.files.push(input.files[i]);
        }
      }
    }
    return this;
  }

  validateName(name) {
    if (name !== undefined) {
      return name.indexOf(" ") > -1;
    } else {
      return false;
    }

  }

  validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }


  public hasError() {
    this.validate();
    return this.$$error.name !== undefined
      || this.$$error.email !== undefined;
  };

  validate() {
    this.$$error = {name: undefined, email: undefined, files: undefined, phoneNumber: undefined, city: undefined, province: undefined};
    if (!this.name || !this.validateName(this.name)) {
      this.$$error.name = 'ENTER_FULL_NAME';
    }
    if (this.email === undefined || this.email === '' || !this.validateEmail(this.email)) {
      this.$$error.email = 'ENTER_VALID_EMAIL';
    }
  };


}
