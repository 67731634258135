import { Deserializable } from './deserializable.model';
import {CandidateAccountService} from "../services/candidateAccount/candidateAccount.service";
import {JobTemplateService} from "../services/job-template/job-template.service";
import {ClientService} from "../services/client/client.service";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Inject} from "@angular/core";

export class JobAlertSubscription implements Deserializable {

  public jobTemplateId;
  public cities;
  public $$error = {jobTemplateId: undefined, cities: undefined};

  constructor() {
    this.cities = new Array();
  }

  deserialize(input: any): this {
    if (input) {
      if (input.jobTemplateId) {
        this.jobTemplateId = input.jobTemplateId;
      }
      this.cities = new Array();
      if (input.cities) {
        for (let i = 0; i < input.cities.length; i++) {
          this.cities.push(input.cities[i]);
        }
      }
    }
    return this;
  }

  hasError() {
    this.validate();
    return this.$$error.jobTemplateId !== undefined
      && this.$$error.cities !== undefined;
  }

  validate() {
    this.$$error = {jobTemplateId: undefined, cities: undefined};
    if (this.jobTemplateId === undefined
      || this.jobTemplateId === '' ) {
      this.$$error.jobTemplateId = 'Please provide a job type';
    }
    if (this.cities === undefined || this.cities.length === 0) {
      this.$$error.cities = 'Please provide a city';
    }
  }

}
