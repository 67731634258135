import { Deserializable } from "./deserializable.model";

export class PositionMinimal implements Deserializable {
  public text;
  public id;
  public title;
  public shortDescription;
  public $$error = {description: undefined};


  deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id;
      }
      if (input.text) {
        this.text = input.text;
      }
      if (input.title) {
        this.title = input.title;
      }
      if (input.shortDescription) {
        this.shortDescription = input.shortDescription;
      }
    }
    return this;
  }

  hasError() {
    this.validate();
    return this.$$error.description !== undefined;
  };

  validate() {
    this.$$error = {description: undefined};
    if (!this.id) {
      this.$$error.description = "Please choose a position";
    }
  };
}
