import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(
    @Inject('BASE_API_URL') private baseUrl: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    const requestUrl = request.url;
    if (requestUrl.includes('assets')) {
      return next.handle(request);
    }if (requestUrl.includes('https://ipinfo.io')) {
      return next.handle(request);
    } else {
      const apiReq = request.clone({ url: `${this.baseUrl}/${requestUrl}`, headers });
      return next.handle(apiReq);
    }
  }

}
