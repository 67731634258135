import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractLevel'
})
export class ContractLevelPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== undefined) {
      switch (value) {
        case 'primary':
          return 'orange-bg';
          break;
        case 'danger':
          return 'yellow-bg';
          break;
        case 'info':
          return 'pink-bg';
          break;
        case 'warning':
          return 'cyan-bg';
          break;
        case 'success':
          return 'green-bg';
          break;
      }
    } else {
      return '';
    }
  }

}
