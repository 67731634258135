import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client/client.service';
import { Profile } from '../../models/profile.model';

@Component({
  selector: 'app-footer-limited',
  templateUrl: './footer-limited.component.html',
  styleUrls: ['./footer-limited.component.scss']
})
export class FooterLimitedComponent extends BaseComponent implements OnInit {

  constructor(private customizationService: CustomizationService,
              private route: ActivatedRoute,
              public translate: TranslateService,
              private clientService: ClientService,
              private router: Router) {
    super();
    this.lang = this.translate.getDefaultLang();
    this.initializeProperties(window, route, this.translate);
  }

  private careerPortalFooter;
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : undefined;
  protected lang: string;

  ngOnInit() {
    BaseComponent.customization.subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.careerPortalFooter = response.careerPortalFooter;
      }
    });
    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;

        if (response && response.descriptions) {
          for (let i = 0; i < response.descriptions.length; i++) {
            if (response.descriptions[i].language === this.translate.getDefaultLang()) {
              this.profile.description = response.descriptions[i].label;
            }
          }
        }
        if (response && response.links) {
          for (let i = 0; i < response.links.length; i++) {
            for (let j = 0; j < response.links[i].labels.length; j++) {
              if (response.links[i].labels[j].language === this.translate.getDefaultLang()) {
                response.links[i].label = response.links[i].labels[j].label;
              }
            }
          }
        }
      }
    });
  }
}
