import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getById(domain: string, clientId: string): Observable<any> {
    return this.http.get('./public/client/' + clientId + '?domain=' + domain);
  }

  getClientByDomain(domain: string) {
    // @ts-ignore
    return this.http.get('./public/client?domain=' + domain, { cache: true });
  }
}
