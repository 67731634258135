import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JobTemplateService } from '../../services/job-template/job-template.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends BaseComponent implements OnInit {

  constructor(private jobTemplateService: JobTemplateService,
              private customizationService: CustomizationService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private router: Router) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  contractTypes = BaseComponent.customizationContent ? BaseComponent.customizationContent.contractTypes : [];
  hierarchicalLevels = BaseComponent.customizationContent ? BaseComponent.customizationContent.hierarchicalLevels : [];
  entities = BaseComponent.customizationContent ? BaseComponent.customizationContent.entities : [];
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];
  jobTemplates: any[];
  clientSectionName: any;

  ngOnInit() {
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {

        // set contract types
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        // set hierarchical levels
        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);

        this.customization = response;

        // set query params for both entities and contract types
        this.route.queryParams.subscribe(params => {
          this.applyFiltersFromQueryParams(params.entities, this.entities, 'entityId');
          this.applyFiltersFromQueryParams(params.contractTypes, this.contractTypes, 'orderNo');
          this.applyFiltersFromQueryParams(params.hierarchicalLevels, this.hierarchicalLevels, 'orderNo');
        });

        // set client section name
        this.clientSectionName = {names: response.clientSectionName};
        this.setInformation(this.clientSectionName, 'names', 'label', this.translate);
      }
    });

    this.jobTemplateService.getJobTemplate(this.domain).subscribe((response: any) => {

      // set job templates
      this.jobTemplates = response;
      this.setInformationList(this.jobTemplates, 'localizedJobs', 'title', this.translate);
      this.setInformationRemoveAccentList(this.jobTemplates, 'title', 'titleClean');

      // set query params for job templates
      this.route.queryParams.subscribe(params => {
        this.applyFiltersFromQueryParams(params.jobTemplates, this.jobTemplates, 'id');
      });
    });

    this.translate.onDefaultLangChange.subscribe((val) => {
      this.lang = this.translate.getDefaultLang();
      if (this.contractTypes) {
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);
      }
      if (this.hierarchicalLevels) {
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);
      }
      if (this.entities) {
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
      }
      if (this.jobTemplates) {
        this.setInformationList(this.jobTemplates, 'localizedJobs', 'title', this.translate);
        this.setInformationRemoveAccentList(this.jobTemplates, 'title', 'titleClean');
      }
      if (this.clientSectionName) {
        this.setInformation(this.clientSectionName, 'names', 'label', this.translate);
      }
    });
  }

  private applyFiltersFromQueryParams(params, source, identifier) {
    if (params) {
      if (params instanceof Array) {
        params.forEach((val) => {
          if (source) {
            source.forEach(item => {
              if (item[identifier] == val) {
                item.on = true;
              }
            });
          }
        });
      } else {
        if (source) {
          source.forEach(item => {
            if (item[identifier] == params) {
              item.on = true;
            }
          });
        }
      }
    } else {
      if (source) {
        source.forEach(item => {
          item.on = false;
        });
      }
    }
  }

  filterChange(source, queryParam: string, identifier: string) {
    const filter = [];
    for (const i in source) {
      if (source[i].on) {
        filter.push(source[i][identifier]);
      }
    }
    this.router.navigate(['/' + this.lang], {queryParams: {[queryParam] : filter}, queryParamsHandling: 'merge'});
  }
}
