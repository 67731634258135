import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
              private translate: TranslateService,
              private customizationService: CustomizationService,
              private route: ActivatedRoute) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  entities = BaseComponent.customizationContent ? BaseComponent.customizationContent.entities : [];
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];

  ngOnInit() {

    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        // set entities
        this.entities = response.entities;
        this.customization = response;
      }
    });
  }

}
