import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { SubmitComponent } from './components/submit/submit.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { JobDetailComponent } from './core/job-detail/job-detail.component';
import { LanguageHostComponent } from './components/language & host/language-host.component';
import { JobAlertComponent } from './components/job-alert/job-alert.component';
import { CandidateAccountComponent } from './components/candidate-account/candidate-account.component';
import {  JobInterviewComponent } from './components/job-interview/job-interview.component';
import {  CandidateComponent } from './components/candidate/candidate.component';
import {FeedbackComponent} from './components/feedback/feedback.component';
import { SurveyComponent } from './components/survey/survey.component';

const routes: Routes = [
  {
    path: '',
    component: LanguageHostComponent,
  }, {
    path: ':lang',
    component: LanguageHostComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'submit',
        component: SubmitComponent
      },
      {
        path: 'job-alert',
        component: JobAlertComponent
      },
      {
        path: 'candidate/:candidateId',
        component: CandidateComponent
      },
      {
        path: 'job-interview/:jobInterviewId',
        component: JobInterviewComponent
      },
      {
        path: 'feedback/:feedbackId',
        component: FeedbackComponent
      },
      {
        path: 'candidate-account/:candidateAccountId',
        component: CandidateAccountComponent
      },
      {
        path: 'candidate-account/:candidateAccountId/:action',
        component: CandidateAccountComponent
      },
      {
        path: 'survey/:surveyId',
        component: SurveyComponent
      },
      {
        path: 'job-details/:jobTitle/:jobId',
        component: JobDetailComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, enableTracing: false, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
