import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: string) {
  }
  private referer : string;

  getJobListByDomain(domain: string): Observable<any> {
    return this.http.get('./public/job/search?domain=' + domain);
  }

  searchQuery(query: any) {
    query.domain = query.domainId;
    delete query.domain;
    return this.http.get('./public/job/search', {params: query});
  }

  search(domain: string, query: any) {
    let lQuery = JSON.parse(JSON.stringify(query));
    if(lQuery && query.domain) {
      delete lQuery.domain;
    }
    if (!isPlatformServer(this.platformId)) {
      this.referer = this.route.snapshot.queryParamMap.get('ref');
      // console.log("#####################################")
      // console.log(this.referer)
      // console.log("#####################################")
      this.http.get('https://ipinfo.io').subscribe((ipInfo: any) => {
        let source = this.referer || this.getSource(this.cookieService.get('referer'));
        let visitor = {
          userId: this.cookieService.get('userId'),
          sessionId: this.cookieService.get('sessionId'),
          referer: this.cookieService.get('referer'),
          source: source,
          url: window.location,
          ipInfo: ipInfo
        };
        this.http.post('./public/visitorInformation?domain=' + domain, visitor).subscribe();
      });
    }
    return this.http.get('./public/job/search?domain=' + domain, {params: lQuery});
  }

  getJobById(domain: string, jobId: string) {
     if (!isPlatformServer(this.platformId)) {
      this.referer = this.route.snapshot.queryParamMap.get('ref');
      // console.log("#####################################")
      // console.log(this.referer)
      // console.log("#####################################")
      this.http.get('https://ipinfo.io').subscribe((ipInfo: any) => {
        let source = this.referer || this.getSource(this.cookieService.get('referer'));
        let visitor = {
          userId: this.cookieService.get('userId'),
          sessionId: this.cookieService.get('sessionId'),
          referer: this.cookieService.get('referer'),
          source: source,
          jobId: jobId,
          url: window.location,
          ipInfo: ipInfo
        };
        this.http.post('./public/visitorInformation?domain=' + domain, visitor).subscribe();
      });
    }
    return this.http.get('./public/job/' + jobId + '?domain=' + domain);
  }

  getSource = function(referer) {
    if (referer === undefined || referer === 'undefined' ||
      referer == null ||
      referer === '') {
      return 'Direct';
    } else if (referer.startsWith('https://jobs.nextal.com') || referer.indexOf(window.location.hostname) > 0) {
      return 'Job Board';
    } else if (referer.startsWith('https://www.glassdoor')) {
      return 'Glassdoor';
    } else if (referer.startsWith('https://ca.talent.com')) {
      return 'Talent.com';
    } else if (referer.startsWith('https://www.facebook') ||
      referer.startsWith('https://l.facebook.com') ||
      referer.startsWith('https://m.facebook.com') ||
      referer.startsWith('http://m.facebook.com')) {
      return 'Facebook';
    } else if (referer.startsWith('https://www.linkedin') ||
      referer.startsWith('https://ca.linkedin') ||
      referer.startsWith('http://lnkd.in')) {
      return 'LinkedIn';
    } else if (referer.startsWith('https://twitter')) {
      return 'Twitter';
    } else if (referer.startsWith('https://www.glassdoor') ||
      referer.startsWith('https://fr.glassdoor.ca') ||
      referer.startsWith('https://en.glassdoor.ca')) {
      return 'Glassdoor';
    } else if (referer.startsWith('https://ca.indeed') ||
      referer.startsWith('https://www.indeed') ||
      referer.startsWith('https://emplois.ca.indeed') ||
      referer.startsWith('android-app://com.linkedin.android')) {
      return 'Indeed';
    } else if (referer.startsWith('https://www.google') ||
      referer.startsWith('https://com.google')) {
      return 'Google';
    } else if (referer.startsWith('https://www.workopolis')) {
      return 'Workopolis';
    } else if (referer.startsWith('https://www.simplyhired.ca')) {
      return 'Simplyhired';
    } else if (referer.startsWith('https://www.bing.com')) {
      return 'Bing';
    } else if (referer.startsWith('https://www.wowjobs.ca') ||
      referer.startsWith('https://m.wowjobs.ca')) {
      return 'WOWjobs';
    } else if (referer.startsWith('https://www.jobillico.com')) {
      return 'JobIllico';
    } else if (referer.startsWith('https://ca.jooble.org')) {
      return 'Jooble';
    } else {
      return referer;
    }
  };
}
