import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobTemplateService {

  constructor(private http: HttpClient) { }

  getJobTemplate(domain: string) {
    return this.http.get('./public/jobTemplate?domain=' + domain);
  }
}
