import {Component, Inject, OnInit} from '@angular/core';
import { JobService } from '../../services/job/job.service';
import { BaseComponent } from '../base.component';
import { CustomizationService } from '../../services/customization/customization.service';
import { ClientService } from '../../services/client/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PipeFilterService } from '../../services/utils/pipeFilter.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BaseComponent implements OnInit {

  constructor(private jobService: JobService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private clientService: ClientService,
              private router: Router,
              @Inject('BASE_API_URL') private baseUrl: string,
              private customizationService: CustomizationService) {
    super();
    this.term = '';
    this.province = '';
    this.city = '';
    this.style = this.style ? this.style : false;
    this.initializeProperties(window, route, this.translate);
  }

  provinces = [];
  cities = [];
  query = {term: '', province: '', city: ''};
  provinceCityDictionary = [];
  term: string;
  province: string;
  city: string ;
  style = BaseComponent.style  ? BaseComponent.style : false;
  styleColor = BaseComponent.styleColor  ? BaseComponent.styleColor : false;
  profile = BaseComponent.profile ? BaseComponent.profile : [];
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : [];

  ngOnInit() {
    BaseComponent.customization.subscribe((response: any) => {
      if (response) {
        this.customization = response;
      }
    });
    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
        if (response.backgroundId) {
          this.styleColor = {
            color : response.backgroundsTextColor,
          };
          this.style = {
            background: 'url(' + this.baseUrl + '/files/' + response.backgroundId + ')',
            'background-repeat': 'no-repeat',
            'background-position-x': 'center'
          };
        }
      }
    });
    this.clientService.getClientByDomain(this.domain).subscribe(response => {
      if (response && response._embedded) {
        const clients = response._embedded.clients;
        clients.forEach(client => {
          if (client.standardAddress) {
            this.provinces.push(client.standardAddress.province);
            this.cities.push(client.standardAddress.city);
            if (!this.provinceCityDictionary[client.standardAddress.province]) {
              this.provinceCityDictionary[client.standardAddress.province] = [];
            }
            this.provinceCityDictionary[client.standardAddress.province].push(client.standardAddress.city);
          }
        });
        this.provinces = PipeFilterService.filterUnique(this.provinces).filter(v => v !== '' && v != null);
        this.provinces = PipeFilterService.filterOrderByAlphabet(this.provinces);
        this.provinces = PipeFilterService.replaceProvinceName(this.provinces);

        this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
        this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.query.term = params.term ? params.term : '';
        this.query.city = params.city ? params.city : '';
        this.query.province = params.province ? params.province : '';
        this.filterCities(this.query.province);
      } else {
      }
    });
  }

  search() {
    if (this.query.term === '' && this.query.city === '' && this.query.province === '') {
      this.router.navigate(['/' + this.lang], {queryParams: {}, queryParamsHandling: 'merge'});
    }
    this.router.navigate(['/' + this.lang], {queryParams: this.query, queryParamsHandling: 'merge'});
  }

  filterCities(provinceSelected) {
    if (provinceSelected !== '') {
      this.cities = PipeFilterService.filterCities(provinceSelected, this.provinceCityDictionary);
    }
    this.cities = PipeFilterService.filterUnique(this.cities).filter(v => v !== '' && v != null);
    this.cities = PipeFilterService.filterOrderByAlphabet(this.cities);
  }
}
