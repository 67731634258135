import { Component, Inject, Injector, OnInit, PLATFORM_ID } from '@angular/core';
import { JobService } from '../../services/job/job.service';
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../services/client/client.service';
import { CustomizationService } from '../../services/customization/customization.service';
import { DomSanitizer, Title} from '@angular/platform-browser';
import { DatePipe , isPlatformServer} from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent extends BaseComponent implements OnInit {

  constructor(private jobService: JobService,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private clientService: ClientService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private titleService: Title,
              private customizationService: CustomizationService,
              private injector: Injector,
              private meta: Meta,
              @Inject(PLATFORM_ID) private platformId: string) {
    super();
    this.meta = meta;
    this.lang = this.translate.getDefaultLang();
    this.initializeProperties(window, route, this.translate);
  }

  private uploadResume = true;
  private style;
  language;
  title;
  shortDescription: string;
  fullDescription: string;
  generalSkills: string;
  contractType: string;
  map = {lat: 0, lng: 0};
  job;
  now;
  contractTypes = BaseComponent.customizationContent ? BaseComponent.customizationContent.contractTypes : [];
  hierarchicalLevels = BaseComponent.customizationContent ? BaseComponent.customizationContent.hierarchicalLevels : [];
  entities = BaseComponent.customizationContent ? BaseComponent.customizationContent.entities : [];
  customization = BaseComponent.customizationContent ? BaseComponent.customizationContent : {};
  baseUrl = BaseComponent.baseUrlContent ? BaseComponent.baseUrlContent : '';
  profile = BaseComponent.profileContent ? BaseComponent.profileContent : '';
  html;
  clientSectionName: any;


  ngOnInit() {
    this.now = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s');
    this.loadJob();
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);

        // set client section name
        this.clientSectionName = {names: response.clientSectionName};
        this.setInformation(this.clientSectionName, 'names', 'label', this.translate);

        if(this.profile.employmentEquality) {
          this.profile.employmentEquality.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.employmentEquity = item.label;
              }
            }
          );
        }

        this.translate.onDefaultLangChange.subscribe(() => {
          this.lang = this.translate.getDefaultLang();
          this.setJobDescription(this.job);
          this.setInformationList(this.contractTypes, 'values', 'label', this.translate);;
          this.setInformation(this.clientSectionName, 'names', 'label', this.translate);
          this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);
          this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
          if(this.profile.employmentEquality) {
            this.profile.employmentEquality.forEach(item => {
                if (item.language === this.translate.getDefaultLang()) {
                  this.profile.employmentEquity = item.label;
                }
              }
            );
          }
        });
      }
    });

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        this.profile = response;
        if(this.profile.employmentEquality) {
          this.profile.employmentEquality.forEach(item => {
              if (item.language === this.translate.getDefaultLang()) {
                this.profile.employmentEquity = item.label;
              }
            }
          );
        }
        window.document.title = response.name + ' - ' + this.title;
      }
    });
  }

  private loadJob() {
    if (isPlatformServer(this.platformId)) {
      const req = this.injector.get('request');
      this.domain = req.get('host').split('.')[0];
      this.customizationService.getClientProfile(this.domain).subscribe(
        (response: any) => {
          this.profile = response;
          if(this.profile.employmentEquality) {
            this.profile.employmentEquality.forEach(item => {
                if (item.language === this.translate.getDefaultLang()) {
                  this.profile.employmentEquity = item.label;
                }
              }
            );
          }
          window.document.title = response.name + ' - ' + this.title;
          this.doLoadJob();
        }, e => {
          this.router.navigate(['/' + this.lang + '/404']);
        }
      );
    } else {
      this.doLoadJob();
    }
  }
  private doLoadJob() {
    this.jobService.getJobById(this.domain, this.getUrlParam(this.route, 'jobId')).subscribe(
      (job: any) => {
        job.clientPhotoUrl = BaseComponent.baseUrlContent + '/files/' + job.clientPhotoId;
        if (job && job.published) {
          this.setJobDescription(job);

          if (job.featurePhotoId) {
            job.featurePhotoUrl = environment.apiUrl + '/files/' + job.featurePhotoId;
          }
          this.titleService.setTitle(this.profile.name + ' - ' + this.title);
          let streetAddress = '';
          if (job.standardAddress && job.standardAddress.address) {
            streetAddress = job.standardAddress.address.split(', ')[0];
          }
          this.html = this.sanitizer.bypassSecurityTrustHtml(
      '<script type="application/ld+json">{\n' +
            '            "@context" : "http://schema.org/",\n' +
            '            "@type" : "JobPosting",\n' +
            '            "title" : "' + this.title + '",\n' +
            '            "description" : "' + this.shortDescription + '",\n' +
            '            "hiringOrganization" : "' + this.profile.name + '",\n' +
            '            "identifier": {\n' +
            '              "@type": "PropertyValue",\n' +
            '              "name": "Job Id",\n' +
            '              "value": "' + job.id + '"\n' +
            '            },\n' +
            '            "datePosted" : "' + job.publishDate.substring(0, 10) + '",\n' +
            '            "employmentType" : "' + job.contractType + '",\n' +
            '            "jobLocation" : {\n' +
            '              "@type" : "Place",\n' +
            '              "address" : {\n' +
            '                "@type" : "PostalAddress",\n' +
            '                "streetAddress" : "' + streetAddress +  '",\n' +
            '                "addressLocality" : "' + (job.standardAddress ? job.standardAddress.city : '') + '",\n' +
            '                "addressRegion" : "' + (job.standardAddress ? job.standardAddress.province : '') + '",\n' +
            '                "postalCode" : "' + (job.standardAddress ? job.standardAddress.postalCode : '') + '",\n' +
            '                "addressCountry": "' + (job.standardAddress ? job.standardAddress.country : '') + '"\n' +
            '              }\n' +
            '            }\n' +
            '          }' +
            '</script>');

          if (job.clientAddress && job._links.client) {
            this.clientService.getById(this.domain, job.clientId).subscribe(
              (client: any) => {
                if (client.standardAddress && client.standardAddress.address) {
                  job.standardAddress = client.standardAddress;
                  job.geocode = client.geocode;
                  this.map.lat = job.geocode[0];
                  this.map.lng = job.geocode[1];
                } else {
                  job.$$noAddress = true;
                }
              }
            );
          } else {
            if (job.geocode) {
              this.map.lat = job.geocode[0];
              this.map.lng = job.geocode[1];
            } else {
              job.$$noAddress = true;
            }
          }

          this.job = job;


        } else {
          this.router.navigate(['/' + this.lang + '/404']);
        }
      }, e => {
        this.router.navigate(['/' + this.lang + '/404']);
      }
    );
  }

  showAddress() {
    return this.domain !== 'cqsb';
  }

  showFileUploader() {
    this.uploadResume = false;
  }

  setJobDescription(job) {
    if (job !== undefined) {
      if (job.clientNames !== undefined && job.clientNames !== null) {
        for (const clientName of job.clientNames) {
          if (clientName.language === this.translate.getDefaultLang()) {
            job.clientName = clientName.name;
          }
        }
      }
      if (job.localizedJobs !== undefined && job.localizedJobs !== null) {
        for (const localizedJob of job.localizedJobs) {
          if (localizedJob.language === this.translate.getDefaultLang()) {
            job.title = localizedJob.title;
            job.shortDescription = localizedJob.shortDescription;
            job.fullDescription = localizedJob.fullDescription;
            job.generalSkills = localizedJob.generalSkills;
            job.shortDescription = localizedJob.shortDescription;
            break;
          }
        }
      }

      if (job.socialAdvantages !== undefined && job.socialAdvantages !== null) {
        job.$$socialAdvantages = [];
        for (const socialAdvantage of job.socialAdvantages) {
          job.$$socialAdvantages.push('SOCIAL_ADVANTAGE_' + socialAdvantage);
        }
      }
      this.title = job.title;
      this.language = this.translate.getDefaultLang();
      window.document.title = this.profile.name + ' - ' + job.title;
      this.shortDescription = job.shortDescription;
      this.fullDescription = job.fullDescription;
      this.generalSkills = job.generalSkills;
      this.shortDescription = job.shortDescription;
      this.contractType = job.contractType;

      this.meta.removeTag('name="og:type"');
      this.meta.removeTag('name="og:title"');
      this.meta.removeTag('name="og:description"');
      this.meta.removeTag('name="og:image"');

      this.meta.removeTag('name="twitter:card"');
      this.meta.removeTag('name="twitter:site"');
      this.meta.removeTag('name="twitter:description"');
      this.meta.removeTag('name="twitter:image"');

      this.meta.addTag({ name: 'og:type', content: 'article' });
      this.meta.addTag({ property: 'og:title', name: 'og:title', content: this.title  });
      this.meta.addTag({ property: 'og:description', name: 'og:description', content: this.shortDescription });
      if (job.featurePhotoId) {
        this.meta.addTag({
          property: 'og:image',
          name: 'og:image',
          content: environment.apiUrl + '/files/' + job.featurePhotoId
        });
      }


      this.meta.addTag({ name: 'twitter:card', content: 'summary' });
      this.meta.addTag({ name: 'twitter:site', content: '@nextal' });
      this.meta.addTag({ name: 'twitter:description', content: this.shortDescription });
      // this.meta.addTag({ name: 'twitter:image', content: 'https://alligator.io/images/front-end-cover.png' });
      if (job.featurePhotoId) {
        this.meta.addTag({
          property: 'twitter:image',
          name: 'twitter:image',
          content: environment.apiUrl + '/files/' + job.featurePhotoId
        });
      }

    }
  }
}
