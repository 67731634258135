import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CalendarEvent} from '../user/user.service';
import {Address, JobInterview, User} from '../job-interview/job-interview.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http: HttpClient) { }

  get(domain: string, candidateId: string, token: string): Observable<any> {
    return this.http.get('./public/domain/' + domain + '/candidate/' + candidateId + '?token=' + token);
  }

  getFeedback(domain: string, candidateId: string, feedbackId: string, passCode: string): Observable<Feedback> {
    return this.http.get<Feedback>(`./public/domain/${domain}/candidate/${candidateId}/feedback/${feedbackId}?passCode=${passCode}`);
  }

  answerFeedback(domain: string, candidateId: string, feedbackId: string, passCode: string, feedback: Feedback): Observable<any> {
    return this.http.post(`./public/domain/${domain}/candidate/${candidateId}/feedback/${feedbackId}/answer?passCode=${passCode}`, feedback);
  }

  denyFeedback(domain: string, candidateId: string, feedbackId: string, passCode: string, feedback: Feedback): Observable<any> {
    return this.http.post(`./public/domain/${domain}/candidate/${candidateId}/feedback/${feedbackId}/deny?passCode=${passCode}`, feedback);
  }

  comment(domain: string, comment: any, candidateId: string, token: string): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidate/' + candidateId + '/comments?token=' + token, comment);
  }

  save(domain: string, candidate: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidate', candidate);
  }

  update(domain: string, candidate: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidate/' + candidate.id, candidate);
  }

  addFile(domain: string, candidate: any, file: any): Observable<any> {
    return this.http.post('./public/domain/' + domain + '/candidate/' + candidate.id + '/file', file);
  }

  createEvent(domain: string, candidadeId: string, interviewId: string, passCode: string, interview: Interview): Observable<Interview> {
    return this.http.post<Interview>('./public/domain/' + domain + '/candidate/' + candidadeId + '/interview/' + interviewId
      + '?passCode=' + passCode, interview);
  }

}

export interface Interview {
  candidateId;
  jobId;
  startDate: Date;
  endDate: Date;
  visioConferenceURL?: string;
  status: string;
}


export interface Feedback {
  $$error: any;
  $$content: any;
  status: string;
  contact: Contact;
  candidate: Candidate;
  contactRelationship: string;
  start: string;
  startDate?: Date;
  end: string;
  endDate?: Date;
  workQuality: number;
  autonomy: number;
  workUnderPressure: number;
  selfControl: number;
  adaptation: number;
  analysis: number;
  openMindedness: number;
  dynamism: number;
  communication: number;
  teamWork: number;
  responsibility: number;
  takingDecision: number;
  disponibility: number;
  punctuality: number;
  workCapacity: number;
  service: number;
  informatiqueHabilities: number;
  frenchLevel: number;
  englishLevel: number;
  rehire: boolean;
  reason: string;
  comment: string;
}

export interface Contact {
  name: string;
}

export interface Candidate {
  name: string;
}
